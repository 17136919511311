import { ExclamationCircleOutlined, PlusCircleFilled } from "@ant-design/icons";
import { SlotContentListItem } from "@api/slotApi";
import PhotoModal from "@component/UploadImageButton/PhotoModal";
import { RootState } from "@redux/rootReducer";
import {
  batchDeleteSlotContent,
  fetchSlotContentList,
  singleDeleteSlotContent,
  updateSelectedSlotContentIds,
  updateSlotContent,
  updateSlotContentListParams,
} from "@redux/slotSlice";
import { Button, Modal, Pagination, Select, Switch, Table } from "antd";
import { SelectValue } from "antd/lib/select";
import { SwitchChangeEventHandler } from "antd/lib/switch";
import moment from "moment";
import React, { FC, ReactText, useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useAppDispatch } from "src/store";
import styled from "styled-components";
import { SlotPatternValue } from "../../constant";
import EditPopup from "./EditPopup";

const Wrapper = styled.div``;
const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  margin-bottom: 10px;
`;
const PageSelect = styled(Select)`
  margin: 0 10px;
`;
const TableContainer = styled.div`
  margin-bottom: 24px;
`;
const ButtonContainer = styled.div``;
const DeleteButton = styled(Button)`
  margin-right: 10px;
`;
const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const Img = styled.img`
  width: 30px;
  height: auto;
`;
const TextButton = styled.p`
  font-size: 13px;
  color: #1890ff;
  cursor: pointer;
  margin: 0;
  margin-right: 22px;
`;
const EditTextContainer = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
`;

const ImageWrapper = styled.div`
  cursor: pointer;
`;

const pageSizeOptions = [20, 50, 100];

const tableHeaderMap: { [pattern: number]: string[] } = {
  [SlotPatternValue.FILM_PATTERN]: ["toShow", "videoUrl", "duration", "lastUpdatedTime"],
  [SlotPatternValue.CAROUSEL_PATTERN]: ["toShow", "name", "url", "duration", "lastUpdatedTime", "position", "media"],
  [SlotPatternValue.FLAT_CAROUSEL_PATTERN]: [
    "toShow",
    "name",
    "url",
    "duration",
    "lastUpdatedTime",
    "position",
    "media",
  ],
  [SlotPatternValue.SINGLE_IMAGE_PATTERN]: [
    "toShow",
    "name",
    "url",
    "duration",
    "lastUpdatedTime",
    "position",
    "media",
  ],
  [SlotPatternValue.CATEGORY_RECOMMENDATION_PATTERN]: [
    "toShow",
    "name",
    "url",
    "duration",
    "lastUpdatedTime",
    "position",
    "media",
  ],
  [SlotPatternValue.LIMITED_TIME_PATTERN_ONE_ROW]: ["toShow", "url", "duration", "lastUpdatedTime"],
  [SlotPatternValue.LIMITED_TIME_PATTERN_TWO_ROWS]: ["toShow", "url", "duration", "lastUpdatedTime"],
  [SlotPatternValue.TOP_ACTIVITY_PATTERN_FOUR_IMG]: [
    "toShow",
    "name",
    "url",
    "duration",
    "lastUpdatedTime",
    "position",
    "media",
  ],
  [SlotPatternValue.TOP_ACTIVITY_PATTERN_THREE_IMG]: [
    "toShow",
    "name",
    "url",
    "duration",
    "lastUpdatedTime",
    "position",
    "media",
  ],
  [SlotPatternValue.TOP_ACTIVITY_PATTERN_TWO_IMG]: [
    "toShow",
    "name",
    "url",
    "duration",
    "lastUpdatedTime",
    "position",
    "media",
  ],
  [SlotPatternValue.BASE_PATTERN_ONE_ROW]: ["toShow", "url", "duration", "lastUpdatedTime", "position", "media"],
  [SlotPatternValue.BASE_PATTERN_TWO_ROWS]: ["toShow", "url", "duration", "lastUpdatedTime", "position", "media"],
  [SlotPatternValue.TOPIC_PATTERN]: ["toShow", "name", "url", "duration", "lastUpdatedTime", "position"],
  [SlotPatternValue.RANKING_PATTERN]: ["toShow", "url", "duration", "lastUpdatedTime"],
  [SlotPatternValue.KEYWORD_SEARCH_PATTERN_ONE_ROW]: [
    "toShow",
    "name",
    "url",
    "duration",
    "lastUpdatedTime",
    "position",
    "media",
  ],
  [SlotPatternValue.KEYWORD_SEARCH_PATTERN_TWO_ROWS]: [
    "toShow",
    "name",
    "url",
    "duration",
    "lastUpdatedTime",
    "position",
    "media",
  ],
  [SlotPatternValue.HTML_TEXT_PATTERN]: ["toShow", "text", "duration", "lastUpdatedTime"],
  [SlotPatternValue.VIP_ONLY_PATTERN]: ["toShow", "url", "duration", "lastUpdatedTime"],
  [SlotPatternValue.VIP_RECOMMENDATION_PATTERN]: ["toShow", "url", "duration", "lastUpdatedTime"],
  [SlotPatternValue.ANNOUNCEMENT_PATTERN]: ["url", "duration", "lastUpdatedTime", "media"],
  [SlotPatternValue.SEARCH_RECOMMEND_PATTERN]: ["promoName", "url", "duration", "lastUpdatedTime"],
  [SlotPatternValue.HTML_TEXT_PRODUCT_PATTERN]: ["toShow", "title", "url", "duration", "lastUpdatedTime"],
};

const defaultContentItem: SlotContentListItem = {
  id: -1,
  navigationTarget: -1,
  name: "",
  media: "",
  content: {
    info: "",
  },
  startAt: moment().startOf("month").format("YYYY-MM-DD HH:mm:ss"),
  endAt: moment().endOf("month").format("YYYY-MM-DD HH:mm:ss"),
  updatedAt: "",
  description: "",
  position: 1,
  toShow: false,
  slot: -1,
  slotType: -1,
  slotPattern: -1,
  newPageRequired: false,
};

const ContentTab: FC = () => {
  const dispatch = useAppDispatch();
  const { id: slotId } = useParams();
  const { slotPattern } = useSelector((state: RootState) => state.slot.slotDetail);
  const slotContentListResult = useSelector((state: RootState) => state.slot.slotContentListResult);
  const slotContentListParams = useSelector((state: RootState) => state.slot.slotContentListParams);
  const isFetchingContentList = useSelector((state: RootState) => state.slot.isFetchingSlotContentList);
  const selectedSlotContentIds = useSelector((state: RootState) => state.slot.selectedSlotContentIds);

  const [editPopupStatus, setEditPopupStatus] = useState<{
    show: boolean;
    mode: "add" | "edit";
    contentItem: SlotContentListItem;
  }>({
    show: false,
    mode: "add",
    contentItem: defaultContentItem,
  });

  const [photoSource, setPhotoSource] = useState<string>("");

  const { count, results: slotContentList } = slotContentListResult;
  const { limit, offset } = slotContentListParams;

  const currentPage = useMemo(() => {
    return Math.floor(offset / limit) + 1;
  }, [limit, offset]);

  useEffect(() => {
    const params = {
      limit: 20,
      offset: 0,
    };

    dispatch(updateSlotContentListParams(params));
    dispatch(fetchSlotContentList(Number(slotId)));
  }, [dispatch, slotId]);

  const handleAddContent = () => {
    const contentItem: SlotContentListItem = {
      ...defaultContentItem,
      slotPattern,
    };

    setEditPopupStatus({
      show: true,
      mode: "add",
      contentItem,
    });
  };

  const handleEditContent = (item: SlotContentListItem) => () => {
    setEditPopupStatus({
      show: true,
      mode: "edit",
      contentItem: item,
    });
  };

  const closeEditPopup = () => {
    setEditPopupStatus({
      show: false,
      mode: "add",
      contentItem: defaultContentItem,
    });
  };

  const handleOnPageSizeChange = (value: SelectValue) => {
    const params = {
      ...slotContentListParams,
      limit: value as number,
    };

    dispatch(updateSlotContentListParams(params));
    dispatch(fetchSlotContentList(Number(slotId)));
  };

  const handleOnPageChange = (page: number) => {
    const params = {
      ...slotContentListParams,
      offset: (page - 1) * limit,
    };

    dispatch(updateSlotContentListParams(params));
    dispatch(fetchSlotContentList(Number(slotId)));
  };

  const handleOnCheck = (values: ReactText[]) => {
    dispatch(updateSelectedSlotContentIds(values as number[]));
  };

  const handleOnSingleDelete = useCallback(
    (slotTargetId: number) => () => {
      Modal.confirm({
        title: "你確定要刪除這筆資料?",
        icon: <ExclamationCircleOutlined />,
        content: "",
        okText: "是",
        cancelText: "否",
        onOk() {
          dispatch(singleDeleteSlotContent(slotTargetId));
        },
      });
    },
    [dispatch],
  );

  const handleOnSwitch = (itemId: number): SwitchChangeEventHandler => (value) => {
    const params = {
      itemId,
      params: {
        toShow: value,
      },
    };
    dispatch(updateSlotContent(params));
  };

  const handleOnBatchDelete = () => {
    Modal.confirm({
      title: "你確定要刪除這些資料?",
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: "是",
      cancelText: "否",
      onOk() {
        dispatch(batchDeleteSlotContent());
      },
    });
  };

  const handleOnPhotoClick = (url: string) => {
    setPhotoSource(url);
  };

  const closePhotoModal = () => {
    setPhotoSource("");
  };

  const tableColumns = useMemo(() => {
    const headerFields = tableHeaderMap[slotPattern];

    const result: any = headerFields
      ? headerFields.map((field) => {
          switch (field) {
            case "toShow":
              return {
                title: "啟用",
                dataIndex: field,
                key: field,
                render: (value: SlotContentListItem["toShow"], data: SlotContentListItem) => (
                  <Switch checked={value} onChange={handleOnSwitch(data.id)} />
                ),
              };
            case "videoUrl":
              return {
                title: "影片網址",
                dataIndex: "media",
                key: "media",
                width: 400,
              };
            case "name":
              return {
                title: "名稱",
                dataIndex: field,
                key: field,
              };
            case "promoName":
              return {
                title: "推廣文案",
                dataIndex: field,
                key: field,
                render: (value: SlotContentListItem["name"], data: SlotContentListItem) => <div>{data.name}</div>,
              };
            case "text":
              return {
                title: "文字",
                dataIndex: "description",
                key: "description",
                render: (value: SlotContentListItem["description"]) => {
                  const element = document.createRange().createContextualFragment(value);
                  return element.textContent;
                },
              };
            case "title":
              return {
                title: "前台顯示標題",
                dataIndex: "name",
                key: "name",
                render: (value: SlotContentListItem["name"]) => {
                  const element = document.createRange().createContextualFragment(value);
                  return element.textContent;
                },
              };
            case "url":
              return {
                title: "網址",
                dataIndex: "content",
                key: "content",
                render: (value: SlotContentListItem["content"]) => value?.link || "",
              };
            case "duration":
              return {
                title: "內容上架時間",
                dataIndex: field,
                key: field,
                render: (value: any, data: SlotContentListItem) => {
                  const { startAt, endAt } = data;
                  if (startAt === null && endAt === null) return "";
                  return `${data.startAt} - ${data.endAt}`;
                },
              };
            case "lastUpdatedTime":
              return {
                title: "最後被編輯時間",
                dataIndex: "updatedAt",
                key: "updatedAt",
              };
            case "position":
              return {
                title: "順序",
                dataIndex: field,
                key: field,
              };
            case "media":
              return {
                title: "圖片",
                dataIndex: field,
                key: field,
                render: (value: SlotContentListItem["media"]) => (
                  <ImageWrapper
                    role="presentation"
                    onClick={() => {
                      handleOnPhotoClick(value);
                    }}
                  >
                    <Img src={value} />
                  </ImageWrapper>
                ),
              };
            default:
              return null;
          }
        })
      : [];

    result.push({
      title: "",
      index: "",
      dataIndex: "",
      width: 170,
      fixed: "right",
      render: (value: any, data: SlotContentListItem) => {
        return (
          <EditTextContainer>
            <TextButton onClick={handleEditContent(data)}>編輯</TextButton>
            <TextButton onClick={handleOnSingleDelete(data.id)}>刪除</TextButton>
          </EditTextContainer>
        );
      },
    });
    return result;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slotPattern, handleOnSingleDelete]);

  return (
    <Wrapper>
      <Header>
        總共
        {count}
        筆， 每頁顯示
        <PageSelect value={limit} onChange={handleOnPageSizeChange}>
          {pageSizeOptions.map((option) => (
            <Select.Option key={option} value={option}>
              {option}
            </Select.Option>
          ))}
        </PageSelect>
        筆
      </Header>
      <TableContainer>
        <Table
          dataSource={slotContentList}
          columns={tableColumns}
          scroll={{ x: "max-content" }}
          rowSelection={{
            onChange: handleOnCheck,
            selectedRowKeys: selectedSlotContentIds,
          }}
          tableLayout="auto"
          pagination={false}
          loading={isFetchingContentList}
          rowKey="id"
        />
      </TableContainer>
      <Footer>
        <ButtonContainer>
          <DeleteButton onClick={handleOnBatchDelete} disabled={selectedSlotContentIds.length === 0}>
            刪除
          </DeleteButton>
          <Button type="primary" icon={<PlusCircleFilled />} onClick={handleAddContent}>
            新增
          </Button>
          <EditPopup
            show={editPopupStatus.show}
            onClose={closeEditPopup}
            mode={editPopupStatus.mode}
            contentItem={editPopupStatus.contentItem}
          />
          {photoSource && <PhotoModal source={photoSource} onClose={closePhotoModal} />}
        </ButtonContainer>
        <Pagination
          current={currentPage}
          total={count}
          onChange={handleOnPageChange}
          showSizeChanger={false}
          pageSize={limit}
        />
      </Footer>
    </Wrapper>
  );
};

export default ContentTab;
