import productApi, { FetchVendorListParam, VendorListResult } from "@api/productApi";
import { StandardResponse } from "src/types";
import * as vendorApi from "@api/vendorApi";
import { VendorContact, VendorContactListParams, VendorContactListResult } from "@page/VendorInfoPage/interface";
import {
  EditReportRegenDetail,
  ReportRegenDetail,
  ReportRegenListParams,
  ReportRegenListResult,
} from "@page/VendorReportRegenPage/interface";
import {
  EditSupplementDetail,
  SupplementDetail,
  SupplementListParams,
  SupplementListResult,
} from "@page/VendorSupplementPage/interface";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import downloadFile from "@utils/downloadFile";
import { message, Modal } from "antd";
import type { RootState } from "./rootReducer";

interface IState {
  isFetchingVendorList: boolean;
  vendorListResult: VendorListResult;
  vendorListParams: FetchVendorListParam;
  filterVendorListResult: VendorListResult;
  filterVendorListParams: FetchVendorListParam;
  selectedVendorIds: number[];
  isFetchingContactList: boolean;
  vendorContactListResult: VendorContactListResult;
  vendorContactListParams: VendorContactListParams;
  isFetchingSupplementList: boolean;
  supplementListResult: SupplementListResult;
  supplementListParams: SupplementListParams;
  supplementDetail: SupplementDetail;
  isFetchingReportRegenList: boolean;
  reportRegenListResult: ReportRegenListResult;
  reportRegenListParams: ReportRegenListParams;
  reportRegenDetail: ReportRegenDetail;
  marinBalanceListResult?: vendorApi.MarginBalanceListResponse;
  marginAddTopList: vendorApi.MarginAddTopList[];
  profitsConversionList: vendorApi.ProfitsConversionList[];
  marginAddTopInfo?: vendorApi.MarginAddTopList;
  profitsConversionInfo?: vendorApi.ProfitsConversionList;
  isMarginFetching: boolean;
  isProfitsFetching: boolean;
  salesRecordList: vendorApi.VenderSalesRecordItem[];
  salesChannelList?: vendorApi.SalesChannelList;
}

const initialState: IState = {
  isFetchingVendorList: false,
  vendorListResult: {
    count: 0,
    previous: "",
    next: "",
    results: [],
  },
  vendorListParams: {
    limit: 20,
    offset: 0,
  },
  filterVendorListResult: {
    count: 0,
    previous: "",
    next: "",
    results: [],
  },
  filterVendorListParams: {
    limit: 20,
    offset: 0,
  },
  selectedVendorIds: [],
  isFetchingContactList: false,
  vendorContactListResult: {
    count: 0,
    previous: "",
    next: "",
    results: [],
  },
  vendorContactListParams: {
    limit: 20,
    offset: 0,
    vendorId: -1,
  },
  isFetchingSupplementList: false,
  supplementListResult: {
    count: 0,
    previous: "",
    next: "",
    results: [],
  },
  supplementListParams: {
    limit: 20,
    offset: 0,
  },
  supplementDetail: {
    id: -1,
    approvalStatus: -1,
    supplementPurpose: -1,
    salesPlanId: -1,
    sku: "",
    extraAmount: 0,
    supplementDate: "",
    staff: { id: -1, name: "" },
    createdAt: "",
    sectionManagerApproval: -1,
    financeApproval: -1,
    vendor: { vendorId: -1, vendorName: "" },
    estimatedSupplementDate: "",
    supplementDescription: "",
    productName: "",
    destroyedAt: "",
    updatedAt: "",
    mode: -1,
  },
  isFetchingReportRegenList: false,
  reportRegenListResult: { count: 0, previous: "", next: "", results: [] },
  reportRegenListParams: {
    limit: 20,
    offset: 0,
  },
  reportRegenDetail: {
    id: -1,
    approvalStatus: -1,
    salesPlanId: -1,
    sku: "",
    amountDiff: 0,
    adjustedCommissionRate: 0,
    affectedEndDate: "",
    affectedStartDate: "",
    staff: { id: -1, name: "" },
    createdAt: "",
    sectionManagerApproval: -1,
    financeApproval: -1,
    destroyedAt: "",
    updatedAt: "",
  },
  marinBalanceListResult: undefined,
  marginAddTopList: [],
  marginAddTopInfo: undefined,
  profitsConversionList: [],
  profitsConversionInfo: undefined,
  isMarginFetching: false,
  isProfitsFetching: false,
  salesRecordList: [],
  salesChannelList: undefined,
};

export const fetchVendorList = createAsyncThunk<VendorListResult>("vendor/fetchVendorList", async (_, thunkApi) => {
  const {
    vendorSlice: { vendorListParams },
  } = thunkApi.getState() as RootState;
  const response = await productApi.fetchVendorList(vendorListParams);
  return response;
});

export const fetchFilterVendorList = createAsyncThunk<VendorListResult, string>(
  "vendor/fetchFilterVendorList",
  async (name, thunkApi) => {
    const params: FetchVendorListParam = {
      nameQ: name || undefined,
      limit: 20,
      offset: 0,
    };

    thunkApi.dispatch(updateFilterVendorListParams(params));
    const response = await productApi.fetchVendorList(params);
    return response;
  },
);

export const loadMoreFilterVendorList = createAsyncThunk("vendor/loadMoreFilterVendorList", async (_, thunkApi) => {
  const {
    vendorSlice: { filterVendorListParams },
  } = thunkApi.getState() as RootState;
  const params: FetchVendorListParam = {
    ...filterVendorListParams,
    offset: filterVendorListParams.offset + filterVendorListParams.limit,
  };

  thunkApi.dispatch(updateFilterVendorListParams(params));
  const response = await productApi.fetchVendorList(params);
  return response;
});

export const clearVendorFilter = createAsyncThunk("vendor/clearVendorFilter", async (_, thunkApi) => {
  thunkApi.dispatch(updateVendorListParams(initialState.vendorListParams));
  thunkApi.dispatch(fetchVendorList());
});

export const updateOwner = createAsyncThunk<void, number>("vendor/updateOwner", async (ownerId, thunkApi) => {
  const {
    vendorSlice: { selectedVendorIds },
  } = thunkApi.getState() as RootState;

  try {
    await vendorApi.fetchOwnerUpdate({ vendorIds: selectedVendorIds, ownerId });
    thunkApi.dispatch(fetchVendorList());
  } catch (error: any) {
    message.error(`${error.message}`);
  }
});

export const modifyVendorCooperation = createAsyncThunk<void, { vendorId: number; isActive: boolean }>(
  "vendor/modifyVendorCooperation",
  async (params, thunkApi) => {
    try {
      await vendorApi.fetchVendorManagement(params);
      thunkApi.dispatch(fetchVendorList());
    } catch (error: any) {
      message.error(`${error.message}`);
    }
  },
);

export const fetchVendorContactList = createAsyncThunk<VendorContactListResult>(
  "vendor/fetchVendorContactList",
  async (_, thunkApi) => {
    const {
      vendorSlice: { vendorContactListParams },
    } = thunkApi.getState() as RootState;

    const response = await vendorApi.fetchVendorContactList(vendorContactListParams);
    return response;
  },
);

export const deleteContact = createAsyncThunk<StandardResponse, number>(
  "vendor/deleteContact",
  async (contactId, thunkApi) => {
    try {
      const response = await vendorApi.fetchDeletedVendorContact(contactId);
      thunkApi.dispatch(fetchVendorContactList());
      return response;
    } catch (error: any) {
      message.error(`無法刪除聯絡人 ${error.message}`);
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const addContact = createAsyncThunk<VendorContact, { contactInfo: VendorContact; vendorId: string }>(
  "vendor/addContact",
  async (params, thunkApi) => {
    const { contactInfo, vendorId } = params;
    try {
      const response = await vendorApi.fetchNewVendorContact(contactInfo, vendorId);
      thunkApi.dispatch(fetchVendorContactList());
      message.success("新增聯絡人成功");
      return response;
    } catch (error: any) {
      message.error(`無法新建聯絡人 ${error.message}`);
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const updateContact = createAsyncThunk<any, VendorContact>(
  "vendor/updateContact",
  async (contactInfo, thunkApi) => {
    const { id } = contactInfo;
    try {
      const response = await vendorApi.fetchModifiedVendorContact(contactInfo, id);
      thunkApi.dispatch(fetchVendorContactList());
      message.success("編輯聯絡人成功");
      return response;
    } catch (error: any) {
      message.error(`無法編輯聯絡人 ${error.message}`);
      return thunkApi.rejectWithValue(error);
    }
  },
);
// -------------- 補單 ---------------
export const fetchSupplementList = createAsyncThunk<SupplementListResult>(
  "vendor/fetchSupplementList",
  async (_, thunkApi) => {
    const {
      vendorSlice: { supplementListParams },
    } = thunkApi.getState() as RootState;

    const response = await vendorApi.fetchSupplementList(supplementListParams);
    return response;
  },
);

export const fetchSupplementDetail = createAsyncThunk("vendor/fetchSupplementDetail", async (id: number) => {
  const response = await vendorApi.fetchSupplementDetail(id);
  return response;
});

export const addSupplement = createAsyncThunk(
  "vendor/addSupplement",
  async (payload: Partial<EditSupplementDetail>, thunkApi) => {
    try {
      const response = await vendorApi.addSupplement(payload);
      message.success("新增成功");
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error.message as string);
    }
  },
);

export const reviewSupplement = createAsyncThunk("vendor/reviewSupplement", async (isApproved: boolean, thunkApi) => {
  const {
    vendorSlice: { supplementDetail },
  } = thunkApi.getState() as RootState;
  const response = await vendorApi.approveSupplement(supplementDetail.id, { isApproved });

  thunkApi.dispatch(fetchSupplementDetail(supplementDetail.id));
  return response;
});

export const editSupplement = createAsyncThunk(
  "vendor/editSupplement",
  async (payload: Partial<EditSupplementDetail>, thunkApi) => {
    const {
      vendorSlice: { supplementDetail },
    } = thunkApi.getState() as RootState;
    const response = await vendorApi.editSupplement(supplementDetail.id, payload);

    thunkApi.dispatch(fetchSupplementDetail(supplementDetail.id));

    return response;
  },
);

export const deleteSupplement = createAsyncThunk("vendor/deleteSupplement", async (id: number, thunkApi) => {
  await new Promise((res) => {
    Modal.confirm({
      title: "警告",
      content: "你確定要作廢月結補單",
      okText: "確認",
      cancelText: "取消",
      onOk: () => {
        res(null);
        return false;
      },
    });
  });

  const response = await vendorApi.deleteSupplement(id);

  thunkApi.dispatch(fetchSupplementList());
  return response;
});

export const exportSupplement = createAsyncThunk("vendor/exportSupplement", async (_, thunkApi) => {
  const {
    vendorSlice: { supplementListParams },
  } = thunkApi.getState() as RootState;

  const response = await vendorApi.exportSupplement(supplementListParams);
  return response;
});

export const fetchSupplementVenderRelation = createAsyncThunk<
  { sku: string; modes: number[]; salesChannel: number[] },
  { sku: string; vendor: number }
>("vendor/fetchSupplementVenderRelation", async (params, _) => {
  const response = await vendorApi.fetchSupplementVenderRelation(params);
  return response;
});

// -------------- 補單 End ---------------
export const fetchReportRegenList = createAsyncThunk<ReportRegenListResult>(
  "vendor/fetchReportRegenList",
  async (_, thunkApi) => {
    const {
      vendorSlice: { reportRegenListParams },
    } = thunkApi.getState() as RootState;

    const response = await vendorApi.fetchReportRegenList(reportRegenListParams);
    return response;
  },
);

export const fetchReportRegenDetail = createAsyncThunk("vendor/fetchReportRegenDetail", async (id: number) => {
  const response = await vendorApi.fetchReportRegenDetail(id);
  return response;
});

export const addReportRegen = createAsyncThunk(
  "vendor/addReportRegen",
  async (payload: Partial<EditReportRegenDetail>) => {
    const response = await vendorApi.addReportRegen(payload);
    return response;
  },
);

export const reviewReportRegen = createAsyncThunk("vendor/reviewReportRegen", async (isApproved: boolean, thunkApi) => {
  const {
    vendorSlice: { reportRegenDetail },
  } = thunkApi.getState() as RootState;
  const response = await vendorApi.approveReportRegen(reportRegenDetail.id, { isApproved });

  thunkApi.dispatch(fetchReportRegenDetail(reportRegenDetail.id));
  return response;
});

export const editReportRegen = createAsyncThunk(
  "vendor/editReportRegen",
  async (payload: Partial<EditReportRegenDetail>, thunkApi) => {
    const {
      vendorSlice: { reportRegenDetail },
    } = thunkApi.getState() as RootState;
    const response = await vendorApi.editReportRegen(reportRegenDetail.id, payload);

    thunkApi.dispatch(fetchReportRegenDetail(reportRegenDetail.id));

    return response;
  },
);

export const deleteReportRegen = createAsyncThunk("vendor/deleteReportRegen", async (id: number, thunkApi) => {
  await new Promise((res) => {
    Modal.confirm({
      title: "警告",
      content: "你確定要作廢月結重跑",
      okText: "確認",
      cancelText: "取消",
      onOk: () => {
        res(null);
        return false;
      },
    });
  });

  const response = await vendorApi.deleteReportRegen(id);

  thunkApi.dispatch(fetchReportRegenList());
  return response;
});

export const exportReportRegen = createAsyncThunk("vendor/exportReportRegen", async (_, thunkApi) => {
  const {
    vendorSlice: { reportRegenListParams },
  } = thunkApi.getState() as RootState;

  const response = await vendorApi.exportReportRegen(reportRegenListParams);
  return response;
});

// 保證金餘額列表
export const fetchMarginBalanceList = createAsyncThunk("vendor/fetchMarginBalanceList", async (vendorId?: number) => {
  const response = await vendorApi.fetchMarginBalanceList(vendorId);
  return response;
});

// 保證金相關
export const fetchMarginSendReceipt = createAsyncThunk(
  "vendor/fetchMarginSendReceipt",
  async (config: { vendorId: number; addTopId: number }, thunkApi) => {
    try {
      const response = await vendorApi.fetchMarginSendReceipt(config.vendorId, config.addTopId);
      const fileName = response.split("Receipts_");
      downloadFile(response, `Receipts_${fileName[1]}`);
      await thunkApi.dispatch(fetchMarginAddTopList(config.vendorId));
      message.success("開立成功");
      return "Success";
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  },
);

export const fetchDeleteMarginAddTop = createAsyncThunk(
  "vendor/fetchDeleteMarginAddTop",
  async (config: { vendorId: number; addTopId: number }, thunkApi) => {
    await vendorApi.fetchDeleteMarginAddTop(config.vendorId, config.addTopId);
    await thunkApi.dispatch(fetchMarginAddTopList(config.vendorId));
    return "Success";
  },
);

export const fetchMarginAddTopList = createAsyncThunk("vendor/fetchMarginAddTopList", async (vendorId: number) => {
  const response = await vendorApi.fetchMarginAddTopList(vendorId);
  return response;
});

export const fetchMarginAddTopInfo = createAsyncThunk(
  "vendor/fetchMarginAddTopInfo",
  async (config: { vendorId: number; addTopId: number }) => {
    const response = await vendorApi.fetchMarginAddTopInfo(config.vendorId, config.addTopId);
    return response;
  },
);

export const fetchCreateMarginAddTop = createAsyncThunk(
  "vendor/fetchCreateMarginAddTop",
  async (config: { vendorId: number; payload: vendorApi.CreateMarginAddTopPayload }, thunkApi) => {
    try {
      const response = await vendorApi.fetchCreateMarginAddTop(config.vendorId, config.payload);
      await thunkApi.dispatch(fetchMarginAddTopList(config.vendorId));
      message.success("建立成功");
      return response;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  },
);

export const fetchEditMarginAddTop = createAsyncThunk(
  "vendor/fetchEditMarginAddTop",
  async (config: { vendorId: number; addTopId: number; payload: vendorApi.EditMarginAddTopPayload }, thunkApi) => {
    try {
      const response = await vendorApi.fetchEditMarginAddTop(config.vendorId, config.addTopId, config.payload);
      await thunkApi.dispatch(fetchMarginAddTopList(config.vendorId));
      message.success("更新成功");
      return response;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  },
);

// 利潤轉換
export const fetchProfitsConversionList = createAsyncThunk(
  "vendor/fetchProfitsConversionList",
  async (vendorId: number) => {
    const response = await vendorApi.fetchProfitsConversionList(vendorId);
    return response;
  },
);

export const fetchProfitsConversionInfo = createAsyncThunk(
  "vendor/fetchProfitsConversionInfo",
  async (config: { vendorId: number; profitsId: number }) => {
    const response = await vendorApi.fetchProfitsConversionInfo(config.vendorId, config.profitsId);
    return response;
  },
);

export const fetchCreateTransferProfits = createAsyncThunk(
  "vendor/fetchCreateTransferProfits",
  async (config: { vendorId: number; payload: vendorApi.CreateTransferProfitsPayload }, thunkApi) => {
    try {
      const response = await vendorApi.fetchCreateTransferProfits(config.vendorId, config.payload);
      await thunkApi.dispatch(fetchProfitsConversionList(config.vendorId));
      message.success("建立成功");
      return response;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  },
);

export const fetchEditTransferProfits = createAsyncThunk(
  "vendor/fetchEditTransferProfits",
  async (config: { vendorId: number; profitsId: number; payload: vendorApi.EditTransferProfitsPayload }, thunkApi) => {
    try {
      const response = await vendorApi.fetchEditTransferProfits(config.vendorId, config.profitsId, config.payload);
      await thunkApi.dispatch(fetchProfitsConversionList(config.vendorId));
      await thunkApi.dispatch(fetchProfitsConversionInfo(config));
      message.success("更新成功");
      return response;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  },
);

export const fetchDeleteTransferProfits = createAsyncThunk(
  "vendor/fetchDeleteTransferProfits",
  async (config: { vendorId: number; profitsId: number }, thunkApi) => {
    try {
      const response = await vendorApi.fetchDeleteTransferProfits(config.vendorId, config.profitsId);
      await thunkApi.dispatch(fetchProfitsConversionInfo(config));
      message.success("審核成功");
      return response;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  },
);

export const fetchApproveTransferProfits = createAsyncThunk(
  "vendor/fetchApproveTransferProfits",
  async (
    config: { vendorId: number; profitsId: number; payload: vendorApi.ApproveTransferProfitsPayload },
    thunkApi,
  ) => {
    try {
      const response = await vendorApi.fetchApproveTransferProfits(config.vendorId, config.profitsId, config.payload);
      await thunkApi.dispatch(fetchProfitsConversionInfo(config));
      message.success("審核成功");
      return response;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  },
);

export const fetchMarginSendInvoice = createAsyncThunk(
  "vendor/fetchMarginSendInvoice",
  async (config: { vendorId: number; profitsId: number }, thunkApi) => {
    try {
      await vendorApi.fetchMarginSendInvoice(config.vendorId, config.profitsId);
      await thunkApi.dispatch(fetchProfitsConversionList(config.vendorId));
      message.success("開立成功");
      return "Success";
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  },
);

export const fetchVenderSalesRecordList = createAsyncThunk(
  "vendor/fetchVenderSalesRecordList",
  async (vendorId: number, thunkApi) => {
    try {
      const response = await vendorApi.fetchVenderSalesRecordList(vendorId);
      return response;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  },
);
export const addVenderSalesRecord = createAsyncThunk(
  "vendor/addVenderSalesRecord",
  async (config: { vendorId: number; addItem: vendorApi.AddSalesRecordItem }, thunkApi) => {
    const { vendorId, addItem } = config;
    try {
      await vendorApi.addVenderSalesRecord(vendorId, addItem);
      await thunkApi.dispatch(fetchVenderSalesRecordList(vendorId));
      message.success("新增成功");
      return "Success";
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  },
);
export const deleteVenderSalesRecord = createAsyncThunk(
  "vendor/deleteVenderSalesRecord",
  async (config: { vendorId: number; recordId: number }, thunkApi) => {
    const { vendorId, recordId } = config;
    try {
      await vendorApi.deleteVenderSalesRecord(vendorId, recordId);
      await thunkApi.dispatch(fetchVenderSalesRecordList(vendorId));
      message.success("刪除成功");
      return "Success";
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  },
);
export const createVendorSalesInvoice = createAsyncThunk(
  "vendor/createVendorSalesInvoice",
  async (config: { vendorId: number; recordId: number }, thunkApi) => {
    const { vendorId, recordId } = config;
    try {
      await vendorApi.createVendorSalesInvoice(vendorId, recordId);
      await thunkApi.dispatch(fetchVenderSalesRecordList(vendorId));
      message.success("開立成功");
      return "Success";
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  },
);
// 取得通路列表
export const fetchSalesChannelList = createAsyncThunk("vendor/fetchSalesChannelList", async () => {
  try {
    const response = await vendorApi.fetchSalesChannelList();
    return response;
  } catch (error: any) {
    return error.message;
  }
});

const vendorSlice = createSlice({
  name: "vendor",
  initialState,
  reducers: {
    resetVendorSlice: () => initialState,
    resetFilterVendorListResult: (state) => {
      state.filterVendorListResult = initialState.filterVendorListResult;
    },
    updateVendorListParams: (state, action: PayloadAction<FetchVendorListParam>) => {
      state.vendorListParams = action.payload;
    },
    updateFilterVendorListParams: (state, action: PayloadAction<FetchVendorListParam>) => {
      state.filterVendorListParams = action.payload;
    },
    updateSelectedVendorIds: (state, action: PayloadAction<number[]>) => {
      state.selectedVendorIds = action.payload;
    },
    updateContactListParams: (state, action: PayloadAction<VendorContactListParams>) => {
      state.vendorContactListParams = action.payload;
    },
    updateVendorSupplementParams: (state, action: PayloadAction<SupplementListParams>) => {
      state.supplementListParams = action.payload;
    },
    clearVendorSupplementParams: (state) => {
      state.supplementListParams = initialState.supplementListParams;
    },
    updateVendorReportRegenParams: (state, action: PayloadAction<ReportRegenListParams>) => {
      state.reportRegenListParams = action.payload;
    },
    clearVendorReportRegenParams: (state) => {
      state.reportRegenListParams = initialState.reportRegenListParams;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchVendorList.pending, (state) => {
      state.isFetchingVendorList = true;
    });
    builder.addCase(fetchVendorList.fulfilled, (state, action) => {
      state.isFetchingVendorList = false;
      state.vendorListResult = action.payload;
    });

    builder.addCase(fetchFilterVendorList.fulfilled, (state, action) => {
      state.filterVendorListResult = action.payload;
    });
    builder.addCase(loadMoreFilterVendorList.fulfilled, (state, action) => {
      state.filterVendorListResult = {
        ...action.payload,
        results: state.filterVendorListResult.results.concat(action.payload.results),
      };
    });
    builder.addCase(fetchVendorContactList.pending, (state) => {
      state.isFetchingContactList = true;
    });
    builder.addCase(fetchVendorContactList.fulfilled, (state, action) => {
      state.isFetchingContactList = false;
      state.vendorContactListResult = action.payload;
    });

    builder.addCase(fetchSupplementList.pending, (state) => {
      state.isFetchingSupplementList = true;
    });
    builder.addCase(fetchSupplementList.fulfilled, (state, action) => {
      state.isFetchingSupplementList = false;
      state.supplementListResult = action.payload;
    });

    builder.addCase(fetchSupplementDetail.fulfilled, (state, action) => {
      state.supplementDetail = action.payload;
    });
    builder.addCase(addSupplement.rejected, (state, action) => {
      message.error(action?.error?.message ?? "未預期的錯誤");
    });

    builder.addCase(addSupplement.fulfilled, (state, action) => {
      window.location.href = `/supplement/${action.payload.result.id}`;
    });
    builder.addCase(reviewSupplement.rejected, (state, action) => {
      message.error(action?.error?.message ?? "未預期的錯誤");
    });

    builder.addCase(fetchReportRegenList.pending, (state) => {
      state.isFetchingReportRegenList = true;
    });
    builder.addCase(fetchReportRegenList.fulfilled, (state, action) => {
      state.isFetchingReportRegenList = false;
      state.reportRegenListResult = action.payload;
    });

    builder.addCase(fetchReportRegenDetail.fulfilled, (state, action) => {
      state.reportRegenDetail = action.payload;
    });
    builder.addCase(addReportRegen.rejected, (state, action) => {
      message.error(action?.error?.message ?? "未預期的錯誤");
    });

    builder.addCase(addReportRegen.fulfilled, (state, action) => {
      window.location.href = `/report-regen/${action.payload.result.id}`;
    });
    builder.addCase(reviewReportRegen.rejected, (state, action) => {
      message.error(action?.error?.message ?? "未預期的錯誤");
    });
    builder.addCase(fetchMarginBalanceList.fulfilled, (state, action) => {
      state.marinBalanceListResult = action.payload;
    });
    builder.addCase(fetchMarginAddTopList.pending, (state) => {
      state.isMarginFetching = true;
    });
    builder.addCase(fetchMarginAddTopList.fulfilled, (state, action) => {
      state.marginAddTopList = action.payload;
      state.isMarginFetching = false;
    });
    builder.addCase(fetchProfitsConversionList.pending, (state) => {
      state.isProfitsFetching = true;
    });
    builder.addCase(fetchProfitsConversionList.fulfilled, (state, action) => {
      state.profitsConversionList = action.payload;
      state.isProfitsFetching = false;
    });
    builder.addCase(fetchMarginAddTopInfo.fulfilled, (state, action) => {
      state.marginAddTopInfo = action.payload;
    });
    builder.addCase(fetchProfitsConversionInfo.fulfilled, (state, action) => {
      state.profitsConversionInfo = action.payload;
    });
    builder.addCase(fetchMarginSendReceipt.pending, (state) => {
      state.isMarginFetching = true;
    });
    builder.addCase(fetchMarginSendReceipt.fulfilled, (state) => {
      state.isMarginFetching = false;
    });
    builder.addCase(fetchMarginSendReceipt.rejected, (state) => {
      state.isMarginFetching = false;
    });
    builder.addCase(fetchVenderSalesRecordList.pending, (state) => {
      state.isFetchingVendorList = true;
    });
    builder.addCase(fetchVenderSalesRecordList.fulfilled, (state, action) => {
      state.isFetchingVendorList = false;
      state.salesRecordList = action.payload;
    });
    builder.addCase(fetchVenderSalesRecordList.rejected, (state) => {
      state.isFetchingVendorList = false;
    });
    builder.addCase(fetchSalesChannelList.fulfilled, (state, action) => {
      state.salesChannelList = action.payload;
    });
  },
});

export const {
  resetVendorSlice,
  updateVendorListParams,
  updateFilterVendorListParams,
  updateContactListParams,
  updateSelectedVendorIds,
  clearVendorSupplementParams,
  updateVendorSupplementParams,
  updateVendorReportRegenParams,
  clearVendorReportRegenParams,
  resetFilterVendorListResult,
} = vendorSlice.actions;

export const vendorState = (root: RootState) => root.vendorSlice;
export default vendorSlice.reducer;
