import React, { useEffect, useState } from "react";
import styled from "styled-components";
import SalePageCategoryApi, { SalePageCategoryNode } from "@api/salePageCategoryApi";
import { Button, Cascader, Form, Space, Row } from "antd";
import PopupBackground from "@component/PopupBackground";
import { attributeState } from "@redux/attributeSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@redux/rootReducer";
import PromotionBindType from "@constant/PromotionBindType";
import { batchAddSalePages } from "@redux/promotionAddSalesSlice";

type Props = {
  onClose: () => void;
};

const Wrapper = styled(Form)`
  width: 600px;
  padding: 28px 38px;
  background-color: #ffffff;
  && .ant-form-item-required.ant-form-item-no-colon::before {
    display: none;
  }
  && .ant-form-item-required.ant-form-item-no-colon::after {
    display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: "*";
  }
`;
const ButtonRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 100px;
`;
const ProductCategoryText = styled.div`
  min-width: 390px;
  margin-right: 13px;
`;
const CascaderWrapper = styled.div`
  position: relative;
`;

const defaultFilter = {
  limit: 20,
  offset: 0,
};

const AddCategoryPopup = (props: Props) => {
  const dispatch = useDispatch();
  const { onClose } = props;
  const [form] = Form.useForm();
  const { productCategoryTagListResult } = useSelector(attributeState);
  const [productCategoryList, setProductCategoryList] = useState<SalePageCategoryNode[]>([]);
  const [categoryId, setCategoryId] = useState<number>(-1);
  const { promotionDetail } = useSelector((state: RootState) => state.promotion);
  const { id: promotionId = -1 } = promotionDetail;

  const onSubmit = () => {
    const params = {
      ...defaultFilter,
      pageIds: promotionId,
      objectIds: [categoryId],
      type: PromotionBindType.CATEGORY,
    };
    dispatch(batchAddSalePages(params));
    onClose();
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await SalePageCategoryApi.fetchSalesCategoryList(true);
      setProductCategoryList(response);
    };
    fetchData();
  }, []);

  return (
    <PopupBackground close={onClose} fixed>
      <Wrapper form={form} colon={false}>
        <Form.Item
          name="estimateShippingDate"
          label="指定分類"
          rules={[
            {
              required: true,
              message: "指定分類",
            },
          ]}
        >
          <div>
            {productCategoryTagListResult.results.map((item) => (
              <Row align="middle" style={{ marginBottom: 12 }}>
                <ProductCategoryText>{item.path}</ProductCategoryText>
              </Row>
            ))}
            <Row>
              <CascaderWrapper>
                <Cascader
                  style={{ width: 390 }}
                  options={productCategoryList}
                  changeOnSelect
                  placeholder="請選擇"
                  onChange={(idList) => {
                    if (idList.length > 0) {
                      setCategoryId(Number(idList[idList.length - 1]));
                    }
                  }}
                />
                {/* {checkEmptyData && <NoticeText>請選擇品類</NoticeText>} */}
              </CascaderWrapper>
            </Row>
          </div>
        </Form.Item>
        <ButtonRow>
          <Space>
            <Button type="default" onClick={onClose}>
              取消
            </Button>
            <Button type="primary" onClick={onSubmit}>
              確認
            </Button>
          </Space>
        </ButtonRow>
      </Wrapper>
    </PopupBackground>
  );
};

export default AddCategoryPopup;
