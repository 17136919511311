import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "@redux/rootReducer";
import { message } from "antd";
import slotApi, {
  FetchSlotListParam,
  SlotListResult,
  SlotListItem,
  SlotDetail,
  CreateSlotParam,
  UpdateSlotParam,
  FetchSlotContentListParam,
  FetchSlotTargetListParam,
  SlotContentListResult,
  SlotContentListItem,
  UpdateSlotContentParam,
  CreateSlotContentParam,
  SlotTargetListResult,
  SlotTargetListItem,
  UpdateSlotTargetParam,
  CreateSlotTargetParam,
} from "@api/slotApi";
import { StandardResponse } from "src/types";

interface IState {
  isFetchingList: boolean;
  slotListResult: SlotListResult;
  slotListParams: FetchSlotListParam;
  selectedSlotIds: number[];
  slotDetail: SlotDetail;
  isFetchingSlotContentList: boolean;
  slotContentListParams: FetchSlotContentListParam;
  slotContentListResult: SlotContentListResult;
  selectedSlotContentIds: number[];
  isFetchingSlotTargetList: boolean;
  slotTargetListParams: FetchSlotTargetListParam;
  slotTargetListResult: SlotTargetListResult;
  selectedSlotTargetIds: number[];
  csvErrorMessage: string;
  showImportCsvPopup: boolean;
}

const initialState: IState = {
  isFetchingList: false,
  slotListResult: {
    count: 0,
    next: "",
    previous: "",
    results: [],
  },
  slotListParams: {
    limit: 20,
    offset: 0,
    slotPattern: -1,
    pageType: -1,
  },
  selectedSlotIds: [],
  slotDetail: {
    id: -1,
    slotPattern: -1,
    slotType: -1,
    name: "",
    startAt: "",
    endAt: "",
    title: "",
  },
  isFetchingSlotContentList: false,
  slotContentListParams: {
    limit: 20,
    offset: 0,
  },
  slotContentListResult: {
    count: 0,
    next: "",
    previous: "",
    results: [],
  },
  selectedSlotContentIds: [],
  isFetchingSlotTargetList: false,
  slotTargetListParams: {
    limit: 20,
    offset: 0,
  },
  slotTargetListResult: {
    count: 0,
    next: "",
    previous: "",
    results: [],
  },
  selectedSlotTargetIds: [],
  csvErrorMessage: "",
  showImportCsvPopup: false,
};

export const fetchSlotList = createAsyncThunk("slot/fetchSlotList", async (_, thunkApi) => {
  const {
    slot: { slotListParams },
  } = thunkApi.getState() as RootState;

  const response = await slotApi.fetchSlotList(slotListParams);
  return response;
});

export const batchDeleteSlot = createAsyncThunk("slot/batchDeleteSlot", async (_, thunkApi) => {
  const {
    slot: {
      selectedSlotIds,
      slotListResult: { results: slotList },
    },
  } = thunkApi.getState() as RootState;

  const selectedSlots = slotList.filter((slt) => selectedSlotIds.includes(slt.pageTypeRelationId));
  const response = await slotApi.batchDeleteSlot(selectedSlots);
  thunkApi.dispatch(updateSelectedSlotIds([]));
  thunkApi.dispatch(fetchSlotList());
  return response;
});

export const singleDeleteSlot = createAsyncThunk("slot/singleDeleteSlot", async (relationId: number, thunkApi) => {
  const {
    slot: {
      slotListResult: { results: slotList },
    },
  } = thunkApi.getState() as RootState;

  const deletedSlot = slotList.find((slt) => slt.pageTypeRelationId === relationId)!;
  const response = await slotApi.batchDeleteSlot([deletedSlot]);
  thunkApi.dispatch(fetchSlotList());
  return response;
});

export const batchUpdateRank = createAsyncThunk("slot/batchUpdateRank", async (params: SlotListItem[], thunkApi) => {
  try {
    // 有頁面關係才可以更新k
    const filteredSlotListItems = params.filter((item) => item.pageTypeRelationId !== null);
    const response = await slotApi.batchUpdateRank(filteredSlotListItems);
    return response;
  } catch {
    // error
    return "";
  } finally {
    thunkApi.dispatch(fetchSlotList());
  }
});

export const createSlot = createAsyncThunk("slot/createSlot", async (params: CreateSlotParam, thunkApi) => {
  try {
    const response = await slotApi.createSlot(params);
    message.success("建立成功");
    return response;
  } catch (error: any) {
    return thunkApi.rejectWithValue(error.message);
  }
});

export const fetchSlotDetail = createAsyncThunk("slot/fetchSlotDetail", async (id: number) => {
  const response = await slotApi.fetchSlotDetail(id);
  return response;
});

export const updateSlot = createAsyncThunk("slot/updateSlot", async (arg: { id: number; params: UpdateSlotParam }) => {
  const { id, params } = arg;
  const response = await slotApi.updateSlot(id, params);
  message.success("已儲存");
  return response;
});

export const fetchSlotContentList = createAsyncThunk("slot/fetchSlotContentList", async (id: number, thunkApi) => {
  const {
    slot: { slotContentListParams },
  } = thunkApi.getState() as RootState;
  const response = await slotApi.fetchSlotContentList(id, slotContentListParams);
  return response;
});

export const updateSlotContent = createAsyncThunk<
  SlotContentListItem,
  { itemId: number; params: UpdateSlotContentParam }
>("slot/updateSlotContent", async (arg, thunkApi) => {
  const {
    slot: { slotDetail },
  } = thunkApi.getState() as RootState;
  const { id: slotId } = slotDetail;
  const { itemId, params } = arg;
  const response = await slotApi.updateSlotContent(slotId, itemId, params);
  thunkApi.dispatch(fetchSlotContentList(slotId));
  return response;
});

export const batchDeleteSlotContent = createAsyncThunk<StandardResponse>(
  "slot/batchDeleteSlotContent",
  async (_, thunkApi) => {
    const {
      slot: { slotDetail, selectedSlotContentIds },
    } = thunkApi.getState() as RootState;
    const { id: slotId } = slotDetail;
    const response = await slotApi.batchDeleteSlotContent(slotId, selectedSlotContentIds);
    thunkApi.dispatch(updateSelectedSlotContentIds([]));
    thunkApi.dispatch(fetchSlotContentList(slotId));
    return response;
  },
);

export const singleDeleteSlotContent = createAsyncThunk<StandardResponse, number>(
  "slot/batchDeleteSlotContent",
  async (targetId, thunkApi) => {
    const {
      slot: { slotDetail },
    } = thunkApi.getState() as RootState;
    const { id: slotId } = slotDetail;

    const response = await slotApi.batchDeleteSlotContent(slotId, [targetId]);
    thunkApi.dispatch(fetchSlotContentList(slotId));
    return response;
  },
);

export const createSlotContent = createAsyncThunk<SlotContentListItem, CreateSlotContentParam>(
  "slot/createSlotContent",
  async (params, thunkApi) => {
    const {
      slot: { slotDetail },
    } = thunkApi.getState() as RootState;
    const { id: slotId } = slotDetail;

    const response = await slotApi.createSlotContent(slotId, params);
    thunkApi.dispatch(fetchSlotContentList(slotId));
    return response;
  },
);

export const fetchSlotTargetList = createAsyncThunk("slot/fetchSlotTargetList", async (_, thunkApi) => {
  const {
    slot: { slotDetail, slotTargetListParams },
  } = thunkApi.getState() as RootState;
  const { id: slotId } = slotDetail;
  const response = await slotApi.fetchSlotTargetList(slotId, slotTargetListParams);
  return response;
});

export const updateSlotTarget = createAsyncThunk<
  SlotTargetListItem,
  { relationId: number; params: UpdateSlotTargetParam }
>("slot/updateSlotTarget", async (arg, thunkApi) => {
  const {
    slot: { slotDetail },
  } = thunkApi.getState() as RootState;

  const { id: slotId } = slotDetail;
  const { relationId, params } = arg;
  const response = await slotApi.updateSlotTarget(slotId, relationId, params);
  thunkApi.dispatch(fetchSlotTargetList());
  return response;
});
export const updateSlotDetail = createAsyncThunk<SlotTargetListItem, { relationId: number; params: any }>(
  "slot/updateSlotDetail",
  async (arg, thunkApi) => {
    try {
      const {
        slot: { slotDetail },
      } = thunkApi.getState() as RootState;

      const { id: slotId } = slotDetail;
      const { relationId, params } = arg;
      const response = await slotApi.updateSlotDetail(slotId, relationId, params);
      thunkApi.dispatch(fetchSlotTargetList());
      message.success("編輯成功");
      return response;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  },
);

export const batchDeleteSlotTarget = createAsyncThunk("slot/batchDeleteSlotTarget", async (_, thunkApi) => {
  const {
    slot: { slotDetail, selectedSlotTargetIds },
  } = thunkApi.getState() as RootState;
  const { id: slotId } = slotDetail;
  const response = await slotApi.batchDeleteSlotTarget(slotId, selectedSlotTargetIds);
  thunkApi.dispatch(updateSelectedSlotTargetIds([]));
  thunkApi.dispatch(fetchSlotTargetList());
  return response;
});

export const singleDeleteSlotTarget = createAsyncThunk<StandardResponse, number>(
  "slot/batchDeleteSlotTarget",
  async (targetId, thunkApi) => {
    const {
      slot: { slotDetail },
    } = thunkApi.getState() as RootState;
    const { id: slotId } = slotDetail;
    const response = await slotApi.batchDeleteSlotTarget(slotId, [targetId]);
    thunkApi.dispatch(fetchSlotTargetList());
    return response;
  },
);

export const createSlotTarget = createAsyncThunk<SlotTargetListItem, CreateSlotTargetParam>(
  "slot/createSlotTarget",
  async (params, thunkApi) => {
    const {
      slot: { slotDetail },
    } = thunkApi.getState() as RootState;
    const { id: slotId } = slotDetail;
    const response = await slotApi.createSlotTarget(slotId, params);
    thunkApi.dispatch(fetchSlotTargetList());
    return response;
  },
);

export const batchCSVUploadSlotSalePages = createAsyncThunk<StandardResponse, File>(
  "slot/batchCSVUploadSlotSalePages",
  async (file, thunkApi) => {
    const {
      slot: { slotDetail },
    } = thunkApi.getState() as RootState;
    const { id: slotId } = slotDetail;
    try {
      const response = await slotApi.batchCSVUploadSalePages(slotId, file);
      message.success("匯入成功");
      thunkApi.dispatch(fetchSlotTargetList());
      return response;
    } catch (error: any) {
      message.error("匯入失敗");
      throw thunkApi.rejectWithValue("error");
    }
  },
);
const slotSlice = createSlice({
  name: "slot",
  initialState,
  reducers: {
    reset: () => initialState,
    resetSlotListParams: (state) => {
      state.slotListParams = initialState.slotListParams;
    },
    updateSlotListParams: (state, action: PayloadAction<FetchSlotListParam>) => {
      state.slotListParams = action.payload;
    },
    updateSelectedSlotIds: (state, action: PayloadAction<number[]>) => {
      state.selectedSlotIds = action.payload;
    },
    updateSlotContentListParams: (state, action: PayloadAction<FetchSlotContentListParam>) => {
      state.slotContentListParams = action.payload;
    },
    updateSlotTargetListParams: (state, action: PayloadAction<FetchSlotTargetListParam>) => {
      state.slotTargetListParams = action.payload;
    },
    updateSelectedSlotContentIds: (state, action: PayloadAction<number[]>) => {
      state.selectedSlotContentIds = action.payload;
    },
    updateSelectedSlotTargetIds: (state, action: PayloadAction<number[]>) => {
      state.selectedSlotTargetIds = action.payload;
    },
    clearCSVErrorMessage: (state) => {
      state.csvErrorMessage = "";
    },
    setShowImportCsvPopup: (state, action: PayloadAction<boolean>) => {
      state.showImportCsvPopup = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSlotList.pending, (state) => {
      state.isFetchingList = true;
      state.slotListResult = {
        count: 0,
        previous: "",
        next: "",
        results: [],
      };
    });
    builder.addCase(fetchSlotList.fulfilled, (state, action) => {
      state.isFetchingList = false;
      state.slotListResult = action.payload;
    });
    builder.addCase(createSlot.fulfilled, (state, action) => {
      state.slotDetail = action.payload;
    });
    builder.addCase(fetchSlotDetail.fulfilled, (state, action) => {
      state.slotDetail = action.payload;
    });
    builder.addCase(updateSlot.fulfilled, (state, action) => {
      state.slotDetail = action.payload;
    });
    builder.addCase(fetchSlotContentList.pending, (state) => {
      state.isFetchingSlotContentList = true;
    });
    builder.addCase(fetchSlotContentList.fulfilled, (state, action) => {
      state.isFetchingSlotContentList = false;
      state.slotContentListResult = action.payload;
    });
    builder.addCase(fetchSlotTargetList.pending, (state) => {
      state.isFetchingSlotTargetList = true;
    });
    builder.addCase(fetchSlotTargetList.fulfilled, (state, action) => {
      state.isFetchingSlotTargetList = false;
      state.slotTargetListResult = action.payload;
    });
    builder.addCase(updateSlotDetail.pending, (state, action) => {
      state.isFetchingSlotTargetList = true;
    });
    builder.addCase(updateSlotDetail.fulfilled, (state, action) => {
      state.isFetchingSlotTargetList = false;
    });
    builder.addCase(batchCSVUploadSlotSalePages.fulfilled, (state) => {
      state.showImportCsvPopup = false;
    });
    builder.addCase(batchCSVUploadSlotSalePages.rejected, (state, action) => {
      state.csvErrorMessage = "匯入失敗，請重新上傳" || action.error.message;
    });
  },
});

export const {
  reset,
  resetSlotListParams,
  updateSlotListParams,
  updateSelectedSlotIds,
  updateSlotContentListParams,
  updateSlotTargetListParams,
  updateSelectedSlotContentIds,
  updateSelectedSlotTargetIds,
  clearCSVErrorMessage,
  setShowImportCsvPopup,
} = slotSlice.actions;
export default slotSlice.reducer;
