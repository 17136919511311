import { StandardResponse } from "src/types";
import CoreAPI from "./CoreAPI";
import { transformAPIKeyToCamel } from "./utils/transformAPIKeyToCamel";
import { transformCamelToSnake } from "./utils/transformCamelToSnake";

const apiClient = new CoreAPI();

interface ISlotApi {
  fetchSlotList: (params: FetchSlotListParam) => Promise<SlotListResult>;
  batchDeleteSlot: (slots: SlotListItem[]) => Promise<StandardResponse>;
  batchUpdateRank: (items: SlotListItem[]) => Promise<StandardResponse>;
  createSlot: (params: CreateSlotParam) => Promise<SlotDetail>;
  fetchSlotDetail: (id: number) => Promise<SlotDetail>;
  updateSlot: (id: number, params: UpdateSlotParam) => Promise<SlotDetail>;
  fetchSlotContentList: (id: number, params: FetchSlotContentListParam) => Promise<SlotContentListResult>;
  updateSlotContent: (slotId: number, itemId: number, params: UpdateSlotContentParam) => Promise<SlotContentListItem>;
  batchDeleteSlotContent: (slotId: number, ids: number[]) => Promise<StandardResponse>;
  createSlotContent: (id: number, params: CreateSlotContentParam) => Promise<SlotContentListItem>;
  fetchSlotTargetList: (id: number, params: FetchSlotTargetListParam) => Promise<SlotTargetListResult>;
  updateSlotTarget: (slotId: number, relationId: number, params: UpdateSlotTargetParam) => Promise<SlotTargetListItem>;
  updateSlotDetail: (slotId: number, relationId: number, params: UpdateSlotTargetParam) => Promise<SlotTargetListItem>;
  batchDeleteSlotTarget: (slotId: number, ids: number[]) => Promise<StandardResponse>;
  createSlotTarget: (slotId: number, params: CreateSlotTargetParam) => Promise<SlotTargetListItem>;
  batchCSVUploadSalePages: (slotId: number, file: File) => Promise<StandardResponse>;
  batchAddSalePages: (slotId: number, salepageId: number[]) => Promise<StandardResponse>;
}

export interface FetchSlotListParam {
  limit: number;
  offset: number;
  nameQ?: string;
  pageType: number;
  pageInfo?: string;
  slotPattern: number;
  startAt?: string;
  endAt?: string;
  ordering?: string;
}

export interface SlotListResult {
  count: number;
  next: string;
  previous: string;
  results: SlotListItem[];
}

export interface SlotListItem {
  id: number;
  name: string;
  title: string;
  startAt: string;
  endAt: string;
  slotType: number;
  slotPattern: number;
  pageTypeRelationId: number;
  pageType: number;
  pageInfo: number;
  position: number;
}

export interface CreateSlotParam {
  name: string;
  slotType: number;
  slotPattern: number;
  title?: string;
  startAt?: string;
  endAt?: string;
}

export interface SlotDetail {
  id: number;
  name: string;
  title: string;
  slotType: number;
  slotPattern: number;
  startAt: string;
  endAt: string;
}

export type UpdateSlotParam = CreateSlotParam;

export interface FetchSlotContentListParam {
  limit: number;
  offset: number;
}

export interface FetchSlotTargetListParam {
  limit: number;
  offset: number;
}

export interface SlotContentListResult {
  count: number;
  next: string;
  previous: string;
  results: SlotContentListItem[];
}

export interface SlotContentListItem {
  id: number;
  navigationTarget: number;
  name: string;
  media: string;
  content: {
    info: string;
    link?: string;
    tagsData?: number[][];
    priceRange?: {
      max: number;
      min: number;
    };
    sortMethod?: number;
  } | null;
  startAt: string;
  endAt: string;
  updatedAt: string;
  description: string;
  position: number;
  toShow: boolean;
  slot: number;
  slotType: number;
  slotPattern: number;
  newPageRequired: boolean;
}

export interface SlotTargetListResult {
  count: number;
  next: string;
  previous: string;
  results: SlotTargetListItem[];
}

export interface UpdateSlotContentParam {
  name?: string;
  url?: string;
  videoUrl?: string;
  description?: string;
  toShow?: boolean;
  position?: number;
  newPageRequired?: boolean;
  imgUrl?: string;
  imgAlt?: string;
  startAt?: string;
  endAt?: string;
}

export interface CreateSlotContentParam {
  name?: string;
  url?: string;
  videoUrl?: string;
  description?: string;
  toShow?: boolean;
  position?: number;
  newPageRequired?: boolean;
  imgUrl?: string;
  imgAlt?: string;
  startAt?: string;
  endAt?: string;
}

export interface SlotTargetListItem {
  id: number;
  pageType: number;
  pageTypeName: string;
  pageInfo: number;
  slot: number;
  toShow: boolean;
  isActive: boolean;
  position: number;
  updatedAt: string;
}

export interface UpdateSlotTargetParam {
  toShow: boolean;
}

export interface CreateSlotTargetParam {
  pageType: number;
  pageInfo?: number;
  position?: number;
}

const slotApi: ISlotApi = {
  fetchSlotList: async (params) => {
    const { offset, limit, startAt, endAt, nameQ, pageType, pageInfo, slotPattern, ordering } = params;
    const getParams = {
      offset,
      limit,
      start_at: startAt,
      end_at: endAt,
      name_q: nameQ === "" ? undefined : nameQ,
      page_type: pageType < 0 ? undefined : pageType,
      page_info: pageInfo === "" ? undefined : pageInfo,
      slot_pattern: slotPattern < 0 ? undefined : slotPattern,
      ordering: ordering === "" ? undefined : ordering,
    };
    const response = await apiClient.getData("/manage/promotion/slots/", getParams);
    return transformAPIKeyToCamel(response.data.result);
  },
  batchDeleteSlot: async (slots) => {
    const params = {
      slots: slots.map((slt) => ({
        slot_id: slt.id,
        relation_id: slt.pageTypeRelationId,
      })),
    };

    const response = await apiClient.putData("/manage/promotion/slots/batch-delete/", params);
    return response.data;
  },
  batchUpdateRank: async (items) => {
    const params = {
      positions: items.map((item) => ({
        relation_id: item.pageTypeRelationId,
        position: item.position,
      })),
    };
    const response = await apiClient.putData("/manage/promotion/slots/batch-positions/", params);
    return response.data;
  },
  createSlot: async (params) => {
    const { name, slotType, slotPattern } = params;
    const putParams = {
      name,
      slot_type: slotType,
      slot_pattern: slotPattern,
    };
    const response = await apiClient.postData("/manage/promotion/slots/", putParams);
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchSlotDetail: async (id) => {
    const response = await apiClient.getData(`/manage/promotion/slots/${id}/`, {});
    return transformAPIKeyToCamel(response.data.result);
  },
  updateSlot: async (id, params) => {
    const { name, slotType, slotPattern, title, startAt, endAt } = params;
    const putParams = {
      name,
      slot_type: slotType,
      slot_pattern: slotPattern,
      title,
      start_at: startAt,
      end_at: endAt,
    };
    const response = await apiClient.patchData(`/manage/promotion/slots/${id}/`, putParams);
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchSlotContentList: async (id, params) => {
    const response = await apiClient.getData(`/manage/promotion/slots/${id}/slot-items/`, params);
    return transformAPIKeyToCamel(response.data);
  },
  updateSlotContent: async (slotId, itemId, params) => {
    const {
      name,
      url,
      videoUrl,
      description,
      toShow,
      position,
      newPageRequired,
      imgUrl,
      imgAlt,
      startAt,
      endAt,
    } = params;
    const imageDirPrefix =
      imgUrl && !imgUrl.startsWith(process.env.REACT_APP_CLOUDFLARE_DOMAIN!) ? `slot/${slotId}/image` : undefined;
    const imageFilePath = imgUrl && !imgUrl.startsWith(process.env.REACT_APP_CLOUDFLARE_DOMAIN!) ? imgUrl : undefined;

    const postParams = {
      name,
      url,
      video_url: videoUrl,
      description,
      to_show: toShow,
      position,
      image_dir_prefix: imageDirPrefix,
      image_file_path: imageFilePath,
      image_alt: imgAlt,
      new_page_required: newPageRequired,
      start_at: startAt,
      end_at: endAt,
    };

    const response = await apiClient.patchData(`/manage/promotion/slots/${slotId}/slot-items/${itemId}/`, postParams);
    return response.data;
  },
  batchDeleteSlotContent: async (slotId, ids) => {
    const putParams = {
      ids,
    };
    const response = await apiClient.putData(`/manage/promotion/slots/${slotId}/slot-items/batch/delete/`, putParams);
    return response.data;
  },
  createSlotContent: async (id, params) => {
    const {
      name,
      url,
      videoUrl,
      description,
      toShow,
      position,
      newPageRequired,
      imgUrl,
      imgAlt,
      startAt,
      endAt,
    } = params;
    const imageDirPrefix = imgUrl && `slot/${id}/image`;
    const imageFilePath = imgUrl;

    const postParams = {
      name,
      url,
      video_url: videoUrl,
      description,
      to_show: toShow,
      position,
      image_dir_prefix: imageDirPrefix,
      image_file_path: imageFilePath,
      image_alt: imgAlt,
      new_page_required: newPageRequired,
      start_at: startAt,
      end_at: endAt,
    };

    const response = await apiClient.postData(`/manage/promotion/slots/${id}/slot-items/`, postParams);
    return response.data;
  },
  fetchSlotTargetList: async (id, params) => {
    const response = await apiClient.getData(`/manage/promotion/slots/${id}/slot-page-types/`, params);
    return transformAPIKeyToCamel(response.data);
  },
  updateSlotTarget: async (slotId, relationId, params) => {
    const { toShow } = params;
    const patchParams = {
      to_show: toShow,
    };
    const response = await apiClient.patchData(
      `/manage/promotion/slots/${slotId}/slot-page-types/${relationId}/`,
      patchParams,
    );
    return response.data;
  },
  updateSlotDetail: async (slotId, relationId, params) => {
    const response = await apiClient.putData(
      `/manage/promotion/slots/${slotId}/slot-page-types/${relationId}/`,
      transformCamelToSnake(params),
    );
    return response.data;
  },
  batchDeleteSlotTarget: async (slotId, ids) => {
    const putParams = {
      ids,
    };
    const response = await apiClient.putData(
      `/manage/promotion/slots/${slotId}/slot-page-types/batch/delete/`,
      putParams,
    );
    return response.data;
  },
  createSlotTarget: async (slotId, params) => {
    const { position, pageType, pageInfo } = params;
    const postParams = {
      position,
      page_type: pageType,
      page_info: pageInfo,
    };
    const response = await apiClient.postData(`/manage/promotion/slots/${slotId}/slot-page-types/`, postParams);
    return response.data;
  },
  batchCSVUploadSalePages: async (slotId: number, file: File) => {
    const formData = new FormData();
    formData.append("csv_file", file);

    const response = await apiClient.postData(
      `/manage/promotion/slots/${slotId}/slot-page-types/batch/csv-upload/`,
      formData,
    );
    return response.data;
  },
  batchAddSalePages: async (slotId: number, salepageIds: number[]) => {
    const postParams = {
      sales_page_l: salepageIds,
    };
    const response = await apiClient.postData(
      `/manage/promotion/slots/${slotId}/slot-page-types/batch-create/`,
      postParams,
    );
    return response.data;
  },
};

export default slotApi;
