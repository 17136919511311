import promotionApi, {
  CreatePromotionParams,
  FetchPromotionListParams,
  PromotionDetail,
  PromotionListResult,
  UpdatePromotionParams,
  CategoryList,
  TagList,
  SalePageList,
  SalePageListParams,
} from "@api/promotionApi";
import saleApi, { FetchSaleListParams, SaleListResult } from "@api/saleApi";
import topicApi, { SalesCategoryOrigin } from "@api/topicApi";
import { StandardResponse } from "src/types";
import PromotionBindType from "@constant/PromotionBindType";
import type { RootState } from "@redux/rootReducer";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";

interface IState {
  isFetchingList: boolean;
  promotionListResult: PromotionListResult;
  promotionListParams: FetchPromotionListParams;
  selectedPromotionIds: number[];
  isFetchingDetail: boolean;
  promotionDetail: PromotionDetail;
  isFetchingSaleList: boolean;
  saleListResult: SaleListResult;
  saleListParams: FetchSaleListParams;
  selectedSaleIds: number[];
  // 批次匯入
  showCSVImportPopup: boolean;
  csvErrorMessage: string;
  // 新增銷售頁的分類
  salesCategories: {
    level1: SalesCategoryOrigin[];
    level2: SalesCategoryOrigin[];
    level3: SalesCategoryOrigin[];
  };
  // 優惠案型
  selectedRuleIds: number[];

  // 指定銷售頁
  salePageList: SalePageList;
  // 指定品牌
  tagList: TagList;
  // 指定分類
  categoryList: CategoryList;
}

export const defaultPageInfo = {
  id: 0,
  title: "",
  description: "",
  backgroundColor: "#ffffff",
  image: {
    id: 0,
    name: "",
    mediaType: -1,
    isActive: false,
    alt: "",
    url: "",
    image: "",
  },
  metaTitle: "",
  metaKeywords: "",
  metaDescription: "",
  sortMethods: [
    {
      id: 1,
      name: "價格(高至低)",
      isDescend: false,
      isDefault: false,
      isActive: true,
    },
    {
      id: 2,
      name: "最新",
      isDescend: false,
      isDefault: true,
      isActive: true,
    },
    {
      id: 3,
      name: "熱銷",
      isDescend: false,
      isDefault: false,
      isActive: true,
    },
    {
      id: 4,
      name: "推薦",
      isDescend: false,
      isDefault: false,
      isActive: true,
    },
    {
      id: 5,
      name: "價格(低至高)",
      isDescend: false,
      isDefault: false,
      isActive: true,
    },
  ],
};

export const initialState: IState = {
  isFetchingList: false,
  promotionListResult: {
    count: 0,
    next: "",
    previous: "",
    results: [],
  },
  promotionListParams: {
    limit: 20,
    offset: 0,
    isActive: -1,
  },
  selectedPromotionIds: [],
  isFetchingDetail: false,
  promotionDetail: {
    title: "",
    pageType: -1,
    destinationLink: "",
    startAt: "",
    endAt: "",
    tag: -1,
    isActive: false,
    isDeleted: false,
    pageInfo: defaultPageInfo,
    rules: [],
    isShow: false,
    isFilterEnabled: false,
  },
  isFetchingSaleList: false,
  saleListResult: {
    count: 0,
    next: "",
    previous: "",
    results: [],
  },
  saleListParams: {
    limit: 20,
    offset: 0,
  },
  selectedSaleIds: [],
  showCSVImportPopup: false,
  csvErrorMessage: "",
  salesCategories: {
    level1: [],
    level2: [],
    level3: [],
  },
  selectedRuleIds: [],
  tagList: {
    statusCode: 0,
    errorMessage: "",
    result: {
      count: 0,
      results: [],
    },
  },
  categoryList: {
    statusCode: 0,
    errorMessage: "",
    result: {
      count: 0,
      results: [],
    },
  },
  salePageList: {
    statusCode: 0,
    errorMessage: "",
    result: {
      count: 0,
      results: [],
    },
  },
};

export const fetchPromotionList = createAsyncThunk("promotion/fetchPromotionList", async (_, thunkApi) => {
  const {
    promotion: { promotionListParams },
  } = thunkApi.getState() as RootState;

  const response = await promotionApi.fetchPromotionList(promotionListParams);
  return response;
});

export const batchDeletePromotions = createAsyncThunk<StandardResponse>(
  "promotion/batchDeletePromotions",
  async (_, thunkApi) => {
    const {
      promotion: { selectedPromotionIds },
    } = thunkApi.getState() as RootState;
    const response = await promotionApi.batchDeletePromotions(selectedPromotionIds);

    thunkApi.dispatch(fetchPromotionList());
    return response;
  },
);

export const singleDeletePromotion = createAsyncThunk<StandardResponse, number>(
  "promotion/singleDeletePromotion",
  async (id, thunkApi) => {
    const response = await promotionApi.batchDeletePromotions([id]);

    thunkApi.dispatch(fetchPromotionList());
    return response;
  },
);

export const updatePromotionIsActive = createAsyncThunk<PromotionDetail, { id: number; isActive: boolean }>(
  "promotion/updatePromotionIsActive",
  async (arg, thunkApi) => {
    const { id, isActive } = arg;
    const response = await promotionApi.updatePromotionIsActive(id, isActive);
    thunkApi.dispatch(fetchPromotionList());
    return response;
  },
);

export const fetchPromotionDetail = createAsyncThunk<PromotionDetail, number>(
  "promotion/fetchPromotionDetail",
  async (id) => {
    const response = await promotionApi.fetchPromotionDetail(id);
    return response;
  },
);

export const createPromotion = createAsyncThunk<PromotionDetail, CreatePromotionParams>(
  "promotion/createPromotion",
  async (params, thunkApi) => {
    try {
      const response = await promotionApi.createPromotion(params);
      const promotioId = response.id!;
      thunkApi.dispatch(fetchPromotionDetail(promotioId));
      message.success("建立成功");
      return response;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  },
);

export const updatePromotion = createAsyncThunk<PromotionDetail, UpdatePromotionParams>(
  "promotion/updatePromotion",
  async (params, thunkApi) => {
    const {
      promotion: {
        promotionDetail: { id },
      },
    } = thunkApi.getState() as RootState;
    try {
      const response = await promotionApi.updatePromotion(id!, params);
      thunkApi.dispatch(fetchPromotionDetail(id!));
      message.success("更新成功");
      return response;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  },
);

export const updatePromotionIsDeleted = createAsyncThunk<PromotionDetail, boolean>(
  "promotion/updatePromotionIsActive",
  async (isDeleted, thunkApi) => {
    const {
      promotion: {
        promotionDetail: { id },
      },
    } = thunkApi.getState() as RootState;
    const response = await promotionApi.updatePromotionIsDeleted(id!, isDeleted);
    thunkApi.dispatch(fetchPromotionList());
    return response;
  },
);

export const fetchSaleList = createAsyncThunk<SaleListResult, number>(
  "promotion/fetchSaleList",
  async (tagId, thunkApi) => {
    const {
      promotion: { saleListParams },
    } = thunkApi.getState() as RootState;

    const fetchParams = {
      ...saleListParams,
      tagId,
    };

    const response = await saleApi.fetchSaleList(fetchParams);

    return response;
  },
);

export const batchCSVUploadSalePages = createAsyncThunk<StandardResponse, File>(
  "promotion/batchCSVUploadSalePages",
  async (file, thunkApi) => {
    const {
      promotion: {
        promotionDetail: { id, tag },
      },
    } = thunkApi.getState() as RootState;

    const salePageParams = {
      id: tag,
      type: PromotionBindType.SALEPAGE,
      limit: 20,
      offset: 0,
    };

    const response = await promotionApi.batchCSVUploadSalePages(id!, file);
    thunkApi.dispatch(fetchSalePageBindRelation(salePageParams));
    return response;
  },
);

export const batchDeleteSalePages = createAsyncThunk<StandardResponse>(
  "promotion/batchDeleteSalePages",
  async (_, thunkApi) => {
    const {
      promotion: {
        promotionDetail: { id, tag },
        selectedSaleIds,
      },
    } = thunkApi.getState() as RootState;
    try {
      const response = await promotionApi.batchDeleteSalePages(id!, selectedSaleIds, PromotionBindType.SALEPAGE);
      const salePageParams = {
        id: tag,
        type: PromotionBindType.SALEPAGE,
        limit: 20,
        offset: 0,
      };
      thunkApi.dispatch(fetchSaleList(tag));
      thunkApi.dispatch(updateSelectedSaleIds([]));
      thunkApi.dispatch(fetchSalePageBindRelation(salePageParams));

      message.success("刪除成功");
      return response;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  },
);

export const singleDeleteSalePage = createAsyncThunk<StandardResponse, number>(
  "promotion/singleDeleteSalePage",
  async (pageId, thunkApi) => {
    const {
      promotion: {
        promotionDetail: { id, tag },
      },
    } = thunkApi.getState() as RootState;
    const params = {
      id: tag,
      type: PromotionBindType.SALEPAGE,
      limit: 10,
      offset: 0,
    };
    try {
      const response = await promotionApi.batchDeleteSalePages(id!, [pageId], PromotionBindType.SALEPAGE);
      thunkApi.dispatch(fetchSaleList(tag));
      thunkApi.dispatch(fetchSalePageBindRelation(params));
      message.success("刪除成功");
      return response;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  },
);
export const deleteBrand = createAsyncThunk<StandardResponse, number>(
  "promotion/deleteBrand",
  async (pageId, thunkApi) => {
    const {
      promotion: {
        promotionDetail: { id, tag },
      },
    } = thunkApi.getState() as RootState;
    const params = {
      id: tag,
      type: PromotionBindType.TAG,
      limit: 10,
      offset: 0,
    };
    try {
      const response = await promotionApi.batchDeleteSalePages(id!, [pageId], PromotionBindType.TAG);
      thunkApi.dispatch(fetchSaleList(tag));
      thunkApi.dispatch(fetchTagBindRelation(params));
      message.success("刪除成功");
      return response;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  },
);

export const deleteCategory = createAsyncThunk<StandardResponse, number>(
  "promotion/deleteCategory",
  async (pageId, thunkApi) => {
    const {
      promotion: {
        promotionDetail: { id, tag },
      },
    } = thunkApi.getState() as RootState;
    const params = {
      id: tag,
      type: PromotionBindType.CATEGORY,
      limit: 10,
      offset: 0,
    };

    try {
      const response = await promotionApi.batchDeleteSalePages(id!, [pageId], PromotionBindType.CATEGORY);
      thunkApi.dispatch(fetchSaleList(tag));
      thunkApi.dispatch(fetchCategoryBindRelation(params));
      message.success("刪除成功");
      return response;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  },
);

export const fetchSalesCategoryList = createAsyncThunk("promotion/fetchSalesCategoryList", async () => {
  const response = await topicApi.fetchSalesCategories();
  return response;
});

export const batchDeleteRules = createAsyncThunk<StandardResponse>(
  "promotion/batchDeleteRules",
  async (_, thunkApi) => {
    const {
      promotion: {
        promotionDetail: { id },
        selectedRuleIds,
      },
    } = thunkApi.getState() as RootState;

    const response = await promotionApi.batchDeleteRules(id!, selectedRuleIds);
    thunkApi.dispatch(fetchPromotionDetail(id!));
    thunkApi.dispatch(updateSelectedRuleIds([]));
    return response;
  },
);

export const fetchSalePageBindRelation = createAsyncThunk(
  "promotion/fetchSalePageBindRelation",
  async (params: SalePageListParams) => {
    const response = await promotionApi.bindRelation(params);

    return response;
  },
);

export const fetchTagBindRelation = createAsyncThunk(
  "promotion/fetchBindRelation",
  async (params: SalePageListParams) => {
    const response = await promotionApi.bindRelation(params);
    return response;
  },
);

export const fetchCategoryBindRelation = createAsyncThunk(
  "promotion/fetchCategoryBindRelation",
  async (params: SalePageListParams) => {
    const response = await promotionApi.bindRelation(params);
    return response;
  },
);

const promotionSlice = createSlice({
  name: "promotion",
  initialState,
  reducers: {
    reset: () => initialState,
    updatePromotionListParams: (state, action: PayloadAction<FetchPromotionListParams>) => {
      state.promotionListParams = action.payload;
    },
    clearPromotionListParams: (state) => {
      state.promotionListParams = initialState.promotionListParams;
    },
    updateSelectedPromotionIds: (state, action: PayloadAction<number[]>) => {
      state.selectedPromotionIds = action.payload;
    },
    updateSaleListParams: (state, action: PayloadAction<FetchSaleListParams>) => {
      state.saleListParams = action.payload;
    },
    updateSelectedSaleIds: (state, action: PayloadAction<number[]>) => {
      state.selectedSaleIds = action.payload;
    },
    updateShowCSVImportPopup: (state, action: PayloadAction<boolean>) => {
      state.showCSVImportPopup = action.payload;
    },
    clearCSVErrorMessage: (state) => {
      state.csvErrorMessage = "";
    },
    updateSelectedRuleIds: (state, action: PayloadAction<number[]>) => {
      state.selectedRuleIds = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPromotionList.pending, (state) => {
      state.isFetchingList = true;
    });
    builder.addCase(fetchPromotionList.fulfilled, (state, action) => {
      state.isFetchingList = false;
      state.promotionListResult = action.payload;
    });
    builder.addCase(fetchPromotionDetail.pending, (state) => {
      state.isFetchingDetail = true;
    });
    builder.addCase(fetchPromotionDetail.fulfilled, (state, action) => {
      state.isFetchingDetail = false;
      state.promotionDetail = action.payload;
    });
    builder.addCase(fetchSaleList.pending, (state) => {
      state.isFetchingSaleList = true;
    });
    builder.addCase(fetchSaleList.fulfilled, (state, action) => {
      state.isFetchingSaleList = false;
      state.saleListResult = action.payload;
    });
    builder.addCase(batchCSVUploadSalePages.fulfilled, (state) => {
      state.showCSVImportPopup = false;
    });
    builder.addCase(batchCSVUploadSalePages.rejected, (state, action) => {
      state.csvErrorMessage = action.error.message || "";
    });
    builder.addCase(fetchSalesCategoryList.fulfilled, (state, action) => {
      state.salesCategories = action.payload;
    });
    builder.addCase(fetchTagBindRelation.fulfilled, (state, action) => {
      state.tagList = action.payload;
    });
    builder.addCase(fetchCategoryBindRelation.fulfilled, (state, action) => {
      state.categoryList = action.payload;
    });
    builder.addCase(fetchSalePageBindRelation.fulfilled, (state, action) => {
      state.salePageList = action.payload;
    });
  },
});

export const {
  reset,
  updatePromotionListParams,
  clearPromotionListParams,
  updateSelectedPromotionIds,
  updateSaleListParams,
  updateSelectedSaleIds,
  updateShowCSVImportPopup,
  clearCSVErrorMessage,
  updateSelectedRuleIds,
} = promotionSlice.actions;

export default promotionSlice.reducer;
