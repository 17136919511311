import CoreAPI from "./CoreAPI";
import { transformAPIKeyToCamel } from "./utils/transformAPIKeyToCamel";
import { transformCamelToSnake } from "./utils/transformCamelToSnake";
import { SalesChannelType } from "./vendorApi";

const apiClient = new CoreAPI({ timeout: 600000 });

interface ISalesReportApi {
  fetchDashboard: (params: FetchDashboardParams) => Promise<Dashboard>;
  fetchVPCSalesList: (params: FetchVPCSalesListParams) => Promise<VPCSalesListResult>;
  exportVPCSalesList: (params: ExportVPCSalesListParams) => Promise<void>;
  exportCSVPricePerOrder: (params: ExportCSVPricePerOrderParams) => Promise<void>;
  fetchVPCSubOrderList: (vpcId: number, params: FetchVPCSubOrderListParams) => Promise<VPCSubOrderListResult>;
  fetchVPCSalePlanList: (vpcId: number, params: FetchVPCSalesPlanListParams) => Promise<VPCSalesPlanListResult>;
}

export interface Dashboard {
  grossMargin: number;
  revenueSum: number;
  costSum: number;
  grossProfitSum: number;
  transferConsignmentDiscountSum: number;
  commissionFeeSum: number;
  postFeeSum: number;
  rentalFeeSum: number;
  salesVolumesSum: number;
  orderCount: number;
}

export enum ContractType {
  NORMAL = 1,
  TEMPORARY = 2,
}
export interface FetchDashboardParams {
  productName?: string;
  products?: string;
  owner?: string;
  skus?: string;
  excludeSku?: string;
  brand?: string;
  category?: number;
  orderCreatedDateAfter?: string;
  orderCreatedDateBefore?: string;
  vpcStartDateAfter?: string;
  vpcStartDateBefore?: string;
  includeGift?: boolean;
  includeAddBuy?: boolean;
  ordering?: "revenue" | "sales_volume" | "gross_margin" | "gross_profit";
  contractType?: string;
  groupByMode?: string;
  orderType?: string;
  salesChannel?: number;
}

export interface VPCSalesListResult extends Dashboard {
  count: number;
  results: VPCSalesListItem[];
}

export interface VPCSalesListItem {
  vpcInfo: {
    vpcId: number;
    productId: number;
    productSku: string;
    owner: {
      id: number;
      name: string;
    };
    productName: string;
    salesVolume: number;
    revenue: number;
    memberGroupDiscount: number;
    salesRuleDiscount: number;
    vendorLogisticsFee: number;
    vendorFee: number;
    commissionFee: number;
    cost: number;
    grossProfit: number;
    rentalFee: number;
    grossMargin: number;
    contractMode: number;
    contractModeStr: string;
    orderCount: number;
    category: string;
    canSalesQty: number;
  };
}

export interface FetchVPCSalesListParams extends FetchDashboardParams {
  limit: number;
  offset: number;
}

export type ExportVPCSalesListParams = FetchDashboardParams;

export interface ExportCSVPricePerOrderParams {
  orderCreatedDateAfter?: string;
  orderCreatedDateBefore?: string;
  vpcStartDateAfter?: string;
  vpcStartDateBefore?: string;
  includeGift?: boolean;
  includeAddBuy?: boolean;
  productName?: string;
  owner?: string;
  sku?: string;
  excludeSku?: string;
  brand?: string;
  category?: number;
}

export interface FetchVPCSubOrderListParams {
  limit: number;
  offset: number;
  includeGift?: boolean;
  includeAddBuy?: boolean;
  orderCreateDateAfter?: string;
  orderCreateDateBefore?: string;
}

export interface VPCSubOrderListResult {
  count: number;
  next: string;
  previous: string;
  results: VPCSubOrderListItem[];
}

export interface VPCSubOrderListItem {
  salesVolume: number;
  orderShipmentId: number;
  createdAt: string;
  processingType: number;
  shippingStatus: number;
  orderId: number;
}

export interface FetchVPCSalesPlanListParams {
  limit: number;
  offset: number;
  includeGift?: boolean;
  includeAddBuy?: boolean;
  orderCreateDateAfter?: string;
  orderCreateDateBefore?: string;
}

export interface VPCSalesPlanListResult {
  count: number;
  next: string;
  previous: string;
  results: VPCSalesPlanListItem[];
}

export interface VPCSalesPlanListItem {
  orderSalesPlanPageId: number;
  cost: number;
  unitPrice: number;
  commissionRate: number;
  salesVolume: number;
  revenue: number;
  planName: string;
  pageName: string;
  planPrice: number;
}

const salesReportApi: ISalesReportApi = {
  fetchDashboard: async (params) => {
    const getParams = transformCamelToSnake(params);
    const response = await apiClient.getData("/manage/vendor/sales-report/dashboard/", getParams);
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchVPCSalesList: async (params) => {
    const getParams = transformCamelToSnake({
      ...params,
      salesChannel: params.salesChannel === SalesChannelType.URMART ? undefined : params.salesChannel, // 後端要求如為 urmart 不傳
    });
    const response = await apiClient.getData("/manage/vendor/sales-report/", getParams);
    return transformAPIKeyToCamel(response.data.result);
  },
  exportVPCSalesList: async (params) => {
    const getParams = transformCamelToSnake(params);
    await apiClient.getData("/manage/vendor/sales-report/csv-download/", getParams);
  },
  exportCSVPricePerOrder: async (params) => {
    const getParams = transformCamelToSnake(params);
    await apiClient.getData("/manage/vendor/sales-report/csv_price_per_order/", getParams);
  },
  fetchVPCSubOrderList: async (vpcId, params) => {
    const getParams = transformCamelToSnake(params);
    const response = await apiClient.getData(`/manage/vendor/sales-report/${vpcId}/order-list/`, getParams);
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchVPCSalePlanList: async (vpcId, params) => {
    const getParams = transformCamelToSnake(params);
    const response = await apiClient.getData(`/manage/vendor/sales-report/${vpcId}/sales-plan-list/`, getParams);
    return transformAPIKeyToCamel(response.data.result);
  },
};

export default salesReportApi;
