import { VendorContact, VendorContactListParams, VendorContactListResult } from "@page/VendorInfoPage/interface";
import { VendorListParams, VendorListResult } from "@page/VendorListPage/interface";
import {
  EditReportRegenDetail,
  ReportRegenDetail,
  ReportRegenListParams,
  ReportRegenListResult,
} from "@page/VendorReportRegenPage/interface";
import {
  EditSupplementDetail,
  SupplementDetail,
  SupplementListParams,
  SupplementListResult,
} from "@page/VendorSupplementPage/interface";
import downloadCSV from "@utils/downloadCSV";
import moment from "moment";
import { ApiResponse, Vendor } from "src/types";
import CoreAPI from "./CoreAPI";
import normalizeVendorDetails from "./utils/normalizeVendorDetails";
import { transformAPIKeyToCamel } from "./utils/transformAPIKeyToCamel";
import { transformCamelToSnake } from "./utils/transformCamelToSnake";

export enum ProfitsTitle {
  FINE,
  SLOT_PURCHASE,
  MARKETING_PROJECT,
  ACCOUNT_DISCOUNT,
}
export enum ProftisReviewStatus {
  Approve = 2,
  REJECT = 3,
}
export const convertProfitsTitle = (type: string) => {
  switch (type) {
    case "罰款":
      return ProfitsTitle.FINE;
    case "版位購買":
      return ProfitsTitle.SLOT_PURCHASE;
    case "行銷合作專案":
      return ProfitsTitle.MARKETING_PROJECT;
    case "帳款折抵":
      return ProfitsTitle.ACCOUNT_DISCOUNT;
    default:
      return -1;
  }
};
export interface CreateMarginAddTopPayload {
  title: string;
  amount: number;
  pdfDirPrefix?: string;
  pdfFilePath?: string;
}
export interface CreateTransferProfitsPayload {
  title: ProfitsTitle;
  amount: number;
}
export interface EditMarginAddTopPayload extends CreateMarginAddTopPayload {
  receiptsDate?: string;
}
export interface EditTransferProfitsPayload extends CreateTransferProfitsPayload {}
export interface ApproveTransferProfitsPayload {
  reviewStatus: ProftisReviewStatus;
}
export interface MarginBalanceListResponse {
  summaryList: {
    name: string;
    depositBalance: number;
  }[];
  balanceSum: number;
}
export interface MarginAddTopList {
  id: number;
  createdAt: string;
  title: string;
  amount: number;
  receiptsDate: string;
  attachment: string;
  receipts: string;
  staff: string;
}
export interface ProfitsConversionList {
  id: number;
  title: string;
  amount: number;
  invoiceNumber: string | null;
  invoicedAt: string | null;
  destroyedAt: string | null;
  vendor: number;
  reviewStatus: string;
  staff: string;
  createdDate: string;
  reviewDate: string | null;
}

export interface VenderSalesRecordItem {
  id: number;
  vendor: number;
  title: string;
  amount: number;
  startDt: string;
  endDt: string;
  remitDt: string;
  createdDt: string;
  attachment: string;
  staff: string;
  invoiceDt: string | null;
}
export interface AddSalesRecordItem {
  title: string;
  amount: number;
  startDt: string;
  endDt: string;
  remitDt: string;
  dirPrefix: string;
  filePath: string;
}

export interface SalesChannelList extends ApiResponse.ListResponseType {
  results: SalesChannelItem[];
}
export interface SalesChannelItem {
  id: number;
  name: string;
  group: {
    name: string;
    mode: number;
    note: string;
  };
  description: string;
  isActive: boolean;
  createdat: string;
}
export enum SalesChannelType {
  URMART = 1,
  STORE_DIRECT = 2,
  STORE_FRANCHISE = 3,
  OTHER = 4,
}

const apiClient = new CoreAPI();

/**
 * 取得廠商列表
 */
export async function fetchVendorList(filterParams: VendorListParams): Promise<VendorListResult> {
  const {
    limit,
    offset,
    vendorName,
    ownerId,
    startDateAfter,
    startDateBefore,
    endDateBefore,
    endDateAfter,
  } = filterParams;
  const req = await apiClient.getData("/manage/vendor/vendors/", {
    limit,
    offset,
    name_q: vendorName || undefined,
    owner: ownerId === -1 ? undefined : ownerId,
    start_date_after: startDateAfter || undefined,
    start_date_before: startDateBefore || undefined,
    end_date_after: endDateBefore || undefined,
    end_date_before: endDateAfter || undefined,
  });
  return transformAPIKeyToCamel(req.data.result);
}

export function fetchVendorDetail(vendorId: number) {
  const req = apiClient.getData(`/manage/vendor/vendors/${vendorId}/`, {});
  return req.then((res) => normalizeVendorDetails(res.data.result));
}

/**
 * 廠商合作關係管理
 */
export function fetchVendorManagement(params: { vendorId: number; isActive?: boolean }) {
  const { isActive, vendorId } = params;

  const req = apiClient.patchData(`/manage/vendor/vendors/${vendorId}/`, {
    is_active: isActive,
  });
  return req.then((res) => res.data.results);
}

/**
 * 取得廠商聯絡人列表
 */
export function fetchVendorContactList(params: VendorContactListParams): Promise<VendorContactListResult> {
  const { ownerName, vendorName, limit, offset, vendorId } = params;
  const req = apiClient.getData("/manage/vendor/vendor-contacts/", {
    name_q: ownerName,
    vendor_name_q: vendorName,
    limit,
    offset,
    vendor_id: vendorId,
  });
  return req.then((res) => transformAPIKeyToCamel(res.data.result));
}

/**
 * 批次修改商開負責人
 */
export function fetchOwnerUpdate(params: { vendorIds: number[]; ownerId: number }) {
  const { vendorIds, ownerId } = params;
  const req = apiClient.putData("/manage/vendor/vendors/set-owner/", {
    vendor_ids: vendorIds,
    owner_id: ownerId,
  });
  return req.then((res) => res.data);
}

/**
 * 取得廠商貨源列表
 */
export async function fetchVendorSource(vendorId: number) {
  const response = await apiClient.getData(`/manage/vendor/vendors/${vendorId}/vpc-modes/`, {});
  return transformAPIKeyToCamel(response.data.result);
}

/**
 * 修改廠商
 */
export function fetchModifiedVendor(params: Vendor.Details) {
  const req = apiClient.patchData(`/manage/vendor/vendors/${params.vendorId}/`, {
    owner: params.bdOwnerId,
    currency: params.currencyId,
    is_active: true,
    phone: params.vendorPhone,
    name: params.vendorName,
    ubn: params.vendorUbn,
    account_name: params.accountName,
    account_number: params.accountNumber,
    bank_name: params.bankName,
    branch_name: params.branchName,
    swift_code: params.swiftCode,
    payment_condition: params.payCondition,
    register_address: params.reisterAddr,
    business_address: params.buinessAddr,
  });
  return req.then((res) => "success");
}

/**
 * 新增廠商
 */
export function fetchNewVendor(params: Vendor.Details) {
  const req = apiClient.postData("/manage/vendor/vendors/", {
    owner: params.bdOwnerId,
    ubn: params.vendorUbn,
    name: params.vendorName,
    phone: params.vendorPhone,
    account_name: params.accountName,
    account_number: params.accountNumber,
    branch_name: params.branchName,
    bank_name: params.bankName,
    currency: params.currencyId,
    register_address: params.reisterAddr,
    business_address: params.buinessAddr,
    payment_condition: params.payCondition,
    swift_code: params.swiftCode,
    is_active: true,
  });
  return req.then((res) => normalizeVendorDetails(res.data.result));
}

/**
 * 下載廠商貨源
 */
const sourceClient = new CoreAPI();
export function fetchVendorSourceFile(vendorId: number, mode: number) {
  const req = sourceClient.getData(`/manage/vendor/vendors/${vendorId}/download-vpc-modes-report/`, {
    mode,
  });

  return req.then((res) => res);
}

/**
 * 修改廠商聯絡人
 */
export function fetchModifiedVendorContact(content: VendorContact, contactId: number) {
  const req = sourceClient.patchData(`/manage/vendor/vendor-contacts/${contactId}/`, {
    name: content.name,
    phone: content.phone,
    email: content.email,
    title: content.title,
    is_major: content.isMajor,
  });
  return req.then((res) => res);
}

/**
 * 新增廠商連絡人
 */
export function fetchNewVendorContact(content: VendorContact, vendorId: string): Promise<VendorContact> {
  const req = sourceClient.postData("/manage/vendor/vendor-contacts/", {
    name: content.name,
    phone: content.phone,
    email: content.email,
    vendor: vendorId,
  });
  return req.then((res) => {
    const { result } = res.data;
    return transformAPIKeyToCamel(result);
  });
}

export function fetchDeletedVendorContact(contactId: number) {
  const req = sourceClient.deleteData(`/manage/vendor/vendor-contacts/${contactId}/`, {});
  return req.then((res) => res.data);
}

// -------------- 補單 ---------------
/**
 * 取得補單列表
 */
export async function fetchSupplementList(filterParams: SupplementListParams): Promise<SupplementListResult> {
  const req = await apiClient.getData("/manage/vendor/supplements/", transformCamelToSnake(filterParams));
  return transformAPIKeyToCamel(req.data.result);
}

/**
 * 匯出補單
 */
export async function exportSupplement(filterParams: SupplementListParams) {
  const req = await apiClient.getData("/manage/vendor/supplements/csv-download/", transformCamelToSnake(filterParams));
  downloadCSV(req);
}

/**
 * 取得補單Detail
 */
export async function fetchSupplementDetail(id: number): Promise<SupplementDetail> {
  const req = await apiClient.getData(`/manage/vendor/supplements/${id}`, {});
  return transformAPIKeyToCamel(req.data.result);
}

/**
 * 新增補單
 */
export async function addSupplement(payload: Partial<EditSupplementDetail>) {
  const req = await apiClient.postData(
    "/manage/vendor/supplements/",
    transformCamelToSnake({
      ...payload,
      estimatedSupplementDate: moment(payload.estimatedSupplementDate).format("YYYY-MM"),
    }),
  );
  return transformAPIKeyToCamel(req.data);
}

/**
 * 修改補單
 */
export async function editSupplement(id: number, payload: Partial<EditSupplementDetail>) {
  const req = await apiClient.patchData(
    `/manage/vendor/supplements/${id}/`,
    transformCamelToSnake({
      ...payload,
      estimatedSupplementDate: moment(payload.estimatedSupplementDate).format("YYYY-MM"),
    }),
  );
  return transformAPIKeyToCamel(req.data);
}

/**
 * 審核補單
 */
export async function approveSupplement(id: number, payload: { isApproved: boolean }) {
  const req = await apiClient.putData(`/manage/vendor/supplements/${id}/approve/`, transformCamelToSnake(payload));
  return transformAPIKeyToCamel(req.data);
}

/**
 * 取得補單sku合作關係
 */
export async function fetchSupplementVenderRelation(params: {
  sku: string;
  vendor?: number;
}): Promise<{ sku: string; modes: number[]; salesChannel: number[] }> {
  const req = await apiClient.getData("/manage/vendor/supplements/check-vpc", params);
  return transformAPIKeyToCamel(req.data.result);
}

/**
 * 作廢補單
 */
export async function deleteSupplement(id: number) {
  const req = await apiClient.deleteData(`/manage/vendor/supplements/${id}/`, {});
  return transformAPIKeyToCamel(req.data);
}

// -------------- 補單 ---------------
/**
 * 取得重跑列表
 */
export async function fetchReportRegenList(filterParams: ReportRegenListParams): Promise<ReportRegenListResult> {
  const req = await apiClient.getData(
    "/manage/vendor/monthly-report-regenerations/",
    transformCamelToSnake(filterParams),
  );
  return transformAPIKeyToCamel(req.data.result);
}

/**
 * 匯出重跑
 */
export async function exportReportRegen(filterParams: ReportRegenListParams) {
  const req = await apiClient.getData(
    "/manage/vendor/monthly-report-regenerations/csv-download/",
    transformCamelToSnake(filterParams),
  );
  downloadCSV(req);
}

/**
 * 取得重跑Detail
 */
export async function fetchReportRegenDetail(id: number): Promise<ReportRegenDetail> {
  const req = await apiClient.getData(`/manage/vendor/monthly-report-regenerations/${id}`, {});
  return transformAPIKeyToCamel(req.data.result);
}

/**
 * 新增重跑
 */
export async function addReportRegen(payload: Partial<EditReportRegenDetail>) {
  const req = await apiClient.postData(
    "/manage/vendor/monthly-report-regenerations/",
    transformCamelToSnake({
      ...payload,
      adjustedCommissionRate: Number(payload.adjustedCommissionRate),
      affectedStartDate: payload?.affectedDate && payload?.affectedDate[0]?.format("YYYY-MM-DD"),
      affectedEndDate: payload?.affectedDate && payload?.affectedDate[1]?.format("YYYY-MM-DD"),
    }),
  );
  return transformAPIKeyToCamel(req.data);
}

/**
 * 修改重跑
 */
export async function editReportRegen(id: number, payload: Partial<EditReportRegenDetail>) {
  const req = await apiClient.patchData(
    `/manage/vendor/monthly-report-regenerations/${id}/`,
    transformCamelToSnake({
      ...payload,
      adjustedCommissionRate: Number(payload.adjustedCommissionRate),
      affectedStartDate: payload?.affectedDate && payload?.affectedDate[0]?.format("YYYY-MM-DD"),
      affectedEndDate: payload?.affectedDate && payload?.affectedDate[1]?.format("YYYY-MM-DD"),
    }),
  );
  return transformAPIKeyToCamel(req.data);
}

/**
 * 審核重跑
 */
export async function approveReportRegen(id: number, payload: { isApproved: boolean }) {
  const req = await apiClient.putData(
    `/manage/vendor/monthly-report-regenerations/${id}/approve/`,
    transformCamelToSnake(payload),
  );
  return transformAPIKeyToCamel(req.data);
}

/**
 * 作廢重跑
 */
export async function deleteReportRegen(id: number) {
  const req = await apiClient.deleteData(`/manage/vendor/monthly-report-regenerations/${id}/`, {});
  return transformAPIKeyToCamel(req.data);
}

// 保證金餘額總表
export async function fetchMarginBalanceList(vendorId?: number): Promise<MarginBalanceListResponse> {
  const payload = {
    id: vendorId,
  };
  const response = await apiClient.getData("/manage/vendor/deposit-balance-summary/", payload);
  return transformAPIKeyToCamel(response.data.result);
}

// 保證金相關
export async function fetchMarginSendReceipt(vendorId: number, addTopId: number): Promise<string> {
  const response = await apiClient.postData(`/manage/vendor/vendors/${vendorId}/deposit/${addTopId}/receipts/`, {});
  return response.data.result;
}
export async function fetchDeleteMarginAddTop(vendorId: number, addTopId: number) {
  await apiClient.deleteData(`/manage/vendor/vendors/${vendorId}/deposit/${addTopId}/`, {});
}
export async function fetchMarginAddTopList(vendorId: number): Promise<MarginAddTopList[]> {
  const response = await apiClient.getData(`/manage/vendor/vendors/${vendorId}/deposit/`, {});
  return transformAPIKeyToCamel(response.data.result);
}
export async function fetchMarginAddTopInfo(vendorId: number, addTopId: number): Promise<MarginAddTopList> {
  const response = await apiClient.getData(`/manage/vendor/vendors/${vendorId}/deposit/${addTopId}/`, {});
  return transformAPIKeyToCamel(response.data.result);
}
export async function fetchCreateMarginAddTop(vendorId: number, payload: CreateMarginAddTopPayload) {
  await apiClient.postData(`/manage/vendor/vendors/${vendorId}/deposit/`, transformCamelToSnake(payload));
}
export async function fetchEditMarginAddTop(vendorId: number, addTopId: number, payload: EditMarginAddTopPayload) {
  await apiClient.putData(`/manage/vendor/vendors/${vendorId}/deposit/${addTopId}/`, transformCamelToSnake(payload));
}

// 利潤轉換
export async function fetchProfitsConversionList(vendorId: number): Promise<ProfitsConversionList[]> {
  const response = await apiClient.getData(`/manage/vendor/vendors/${vendorId}/transfer-profits/`, {});
  return transformAPIKeyToCamel(response.data.result);
}
export async function fetchProfitsConversionInfo(vendorId: number, profitsId: number): Promise<ProfitsConversionList> {
  const response = await apiClient.getData(`/manage/vendor/vendors/${vendorId}/transfer-profits/${profitsId}/`, {});
  return transformAPIKeyToCamel(response.data.result);
}
export async function fetchCreateTransferProfits(vendorId: number, payload: CreateTransferProfitsPayload) {
  await apiClient.postData(`/manage/vendor/vendors/${vendorId}/transfer-profits/`, transformCamelToSnake(payload));
}
export async function fetchEditTransferProfits(
  vendorId: number,
  profitsId: number,
  payload: EditTransferProfitsPayload,
) {
  await apiClient.putData(
    `/manage/vendor/vendors/${vendorId}/transfer-profits/${profitsId}/`,
    transformCamelToSnake(payload),
  );
}
export async function fetchDeleteTransferProfits(vendorId: number, profitsId: number) {
  await apiClient.deleteData(`/manage/vendor/vendors/${vendorId}/transfer-profits/${profitsId}/`, {});
}
export async function fetchApproveTransferProfits(
  vendorId: number,
  profitsId: number,
  payload: ApproveTransferProfitsPayload,
) {
  await apiClient.putData(
    `/manage/vendor/vendors/${vendorId}/transfer-profits/${profitsId}/approve/`,
    transformCamelToSnake(payload),
  );
}
export async function fetchMarginSendInvoice(vendorId: number, profitsId: number) {
  await apiClient.postData(`/manage/vendor/vendors/${vendorId}/transfer-profits/${profitsId}/invoice/`, {});
}

// 銷售紀錄
export async function fetchVenderSalesRecordList(vendorId: number): Promise<VenderSalesRecordItem[]> {
  const req = await apiClient.getData(`/manage/vendor/vendors/${vendorId}/profit-sales-records/`, {});
  return transformAPIKeyToCamel(req.data.result);
}
export async function addVenderSalesRecord(
  vendorId: number,
  addData: AddSalesRecordItem,
): Promise<VenderSalesRecordItem> {
  const params = transformCamelToSnake({
    ...addData,
    dirPrefix: `vendor/profit_sales_records/attachments/${vendorId}`,
  });
  const req = await apiClient.postData(`/manage/vendor/vendors/${vendorId}/profit-sales-records/`, params);
  return transformAPIKeyToCamel(req.data.result);
}
export async function deleteVenderSalesRecord(vendorId: number, recordId: number): Promise<VenderSalesRecordItem> {
  const req = await apiClient.deleteData(`/manage/vendor/vendors/${vendorId}/profit-sales-records/${recordId}`, {});
  return transformAPIKeyToCamel(req.data.result);
}
export async function createVendorSalesInvoice(vendorId: number, recordId: number) {
  const req = await apiClient.postData(
    `/manage/vendor/vendors/${vendorId}/profit-sales-records/${recordId}/invoice/`,
    {},
  );
  return transformAPIKeyToCamel(req.data.result);
}

//  取得通路列表
export async function fetchSalesChannelList(): Promise<SalesChannelList> {
  const response = await apiClient.getData("/manage/vendor/sales-channel/", {});
  return transformAPIKeyToCamel(response.data.result);
}
