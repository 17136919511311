import saleApi, { FetchSaleListParams, SaleListResult } from "@api/saleApi";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "@redux/rootReducer";
import { message } from "antd";
import tagApi, { TagListResult, FetchTagListParam } from "@api/tagApi";
import promotionApi from "@api/promotionApi";
import {
  fetchSaleList as fetchEditPageSaleList,
  fetchTagBindRelation,
  fetchCategoryBindRelation,
  fetchSalePageBindRelation,
} from "@redux/promotionSlice";
import PromotionBindType from "@constant/PromotionBindType";

interface IState {
  isFetchingBrand: boolean;
  brandListResult: TagListResult;
  brandListParam: FetchTagListParam;
  isFetchingSaleList: boolean;
  saleListResult: SaleListResult;
  saleListFilter: FetchSaleListParams;
  selectedSalePageIds: number[];
}

const initialState: IState = {
  isFetchingBrand: false,
  brandListResult: {
    count: 0,
    previous: "",
    next: "",
    results: [],
  },
  brandListParam: {
    typeNameQ: "品牌",
    nameQ: "",
    limit: 20,
    offset: 0,
  },
  isFetchingSaleList: false,
  saleListResult: {
    count: 0,
    previous: "",
    next: "",
    results: [],
  },
  saleListFilter: {
    limit: 50,
    offset: 0,
  },
  selectedSalePageIds: [],
};

export const fetchBrandList = createAsyncThunk("promotionAddSales/fetchBrandList", async (name: string, thunkApi) => {
  const params: FetchTagListParam = {
    typeNameQ: "品牌",
    nameQ: name,
    limit: 20,
    offset: 0,
  };

  thunkApi.dispatch(updateBrandListParam(params));
  const response = await tagApi.fetchTagList(params);
  return response;
});

export const loadMoreBrandList = createAsyncThunk("promotionAddSales/loadMoreBrandList", async (_, thunkApi) => {
  const {
    promotionAddSales: { brandListParam },
  } = thunkApi.getState() as RootState;

  const params: FetchTagListParam = {
    ...brandListParam,
    offset: brandListParam.offset + brandListParam.limit,
  };

  thunkApi.dispatch(updateBrandListParam(params));
  const response = await tagApi.fetchTagList(params);
  return response;
});

export const fetchSaleList = createAsyncThunk("promotionAddSales/fetchSaleList", async (_, thunkApi) => {
  const {
    promotionAddSales: { saleListFilter },
    promotion: {
      promotionDetail: { tag: tagId },
    },
  } = thunkApi.getState() as RootState;

  const fetchParams = {
    ...saleListFilter,
    excludeTagId: tagId > 0 ? tagId : undefined,
  };
  const response = await saleApi.fetchSaleList(fetchParams);
  return response;
});

export const clearSaleListFilter = createAsyncThunk("promotionAddSales/clearSaleListFilter", async (_, thunkApi) => {
  const {
    promotionAddSales: { saleListFilter },
  } = thunkApi.getState() as RootState;

  const celaredFilter = {
    offset: 0,
    limit: saleListFilter.limit,
  };

  thunkApi.dispatch(updateSaleListFilter(celaredFilter));
  thunkApi.dispatch(fetchSaleList());
});

export const batchAddSalePages = createAsyncThunk(
  "promotionAddSales/batchAddSalePages",
  async (params: { pageIds: number; objectIds: number[]; type: number; limit: number; offset: number }, thunkApi) => {
    const {
      promotion: { promotionDetail },
    } = thunkApi.getState() as RootState;

    const { id: promotionId = -1, tag: tagId } = promotionDetail;
    const { objectIds, type, limit, offset } = params;

    try {
      const response = await promotionApi.batchAddSalePages(promotionId, objectIds, type);
      message.success("新增成功");
      thunkApi.dispatch(fetchEditPageSaleList(tagId));
      const tagParams = {
        id: tagId,
        type: PromotionBindType.TAG,
        limit,
        offset,
      };
      const categoryParams = {
        id: tagId,
        type: PromotionBindType.CATEGORY,
        limit,
        offset,
      };
      const salePageParams = {
        id: tagId,
        type: PromotionBindType.SALEPAGE,
        limit,
        offset,
      };
      thunkApi.dispatch(fetchTagBindRelation(tagParams));
      thunkApi.dispatch(fetchCategoryBindRelation(categoryParams));
      thunkApi.dispatch(fetchSalePageBindRelation(salePageParams));

      return response;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  },
);
const promotionAddSalesSlice = createSlice({
  name: "promotionAddSales",
  initialState,
  reducers: {
    reset: () => initialState,
    updateBrandListParam: (state, action: PayloadAction<FetchTagListParam>) => {
      state.brandListParam = action.payload;
    },
    updateSaleListFilter: (state, action: PayloadAction<FetchSaleListParams>) => {
      state.saleListFilter = action.payload;
    },
    updateSelectedSalePageIds: (state, action: PayloadAction<number[]>) => {
      state.selectedSalePageIds = action.payload;
    },
    resetSaleList: (state) => {
      state.saleListResult = initialState.saleListResult;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchBrandList.pending, (state) => {
      state.isFetchingBrand = true;
    });
    builder.addCase(fetchBrandList.fulfilled, (state, action) => {
      state.isFetchingBrand = false;
      state.brandListResult = action.payload;
    });
    builder.addCase(loadMoreBrandList.fulfilled, (state, action) => {
      state.brandListResult = {
        ...action.payload,
        results: state.brandListResult.results.concat(action.payload.results),
      };
    });
    builder.addCase(fetchSaleList.pending, (state) => {
      state.isFetchingSaleList = true;
    });
    builder.addCase(fetchSaleList.fulfilled, (state, action) => {
      state.isFetchingSaleList = false;
      state.saleListResult = action.payload;
    });
  },
});

export const {
  reset,
  updateBrandListParam,
  updateSaleListFilter,
  updateSelectedSalePageIds,
  resetSaleList,
} = promotionAddSalesSlice.actions;

export default promotionAddSalesSlice.reducer;
